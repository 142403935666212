import { Game, Point } from 'pixie-dust';
import { useEffect, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { useStore } from '../store';
import { translatePointForPlayer } from '../utils/point';

export function useGame() {
  const [game, setGame] = useState<Game | null>(null);
  const [historicGame, setHistoricGame] = useState<Game | null>(null);

  const gameData = useStore(useShallow((state) => state.game));
  const historicGameData = useStore(useShallow((state) => state.historicGame));
  const userId = useStore((state) => state.userId);
  const player = useStore((state) => state.player);

  useEffect(() => {
    if (!gameData) {
      setGame(null);
      return;
    }

    try {
      const game = Game.fromJSON(gameData, (point: Point) =>
        player !== null ? translatePointForPlayer(point, player) : point,
      );
      setGame(game);
    } catch (error) {
      console.error('Failed to load game:', error);
      setGame(null);
    }
  }, [player, userId, gameData]);

  useEffect(() => {
    if (!historicGameData) {
      setHistoricGame(null);
      return;
    }

    try {
      const game = Game.fromJSON(historicGameData, (point: Point) =>
        player !== null ? translatePointForPlayer(point, player) : point,
      );
      setHistoricGame(game);
    } catch (error) {
      console.error('Failed to load game:', error);
      setGame(null);
    }
  }, [player, userId, historicGameData]);

  return { game, historicGame };
}
