import { Board } from '../Board';
import { Player } from '../constants';
import { Piece } from '../pieces';
import { getPieceScore } from './mapping';

export function getCapturedPiecesByPlayer(pieces: Piece[], player: Player) {
  return pieces.filter((p) => p.player === player && p.captured);
}

export function getUncapturedPiecesByPlayer(pieces: Piece[], player: Player) {
  return pieces.filter((p) => p.player === player && !p.captured);
}

export function getScoreDeltaForPlayer(pieces: Piece[], player: Player) {
  const players = [player, Board.getOtherPlayer(player)];
  const [selfBoardScore, opponentBoardScore] = players.map((p) =>
    getUncapturedPiecesByPlayer(pieces, p)
      .map((piece) => getPieceScore(piece.key))
      .reduce((a, b) => a + b, 0),
  );
  return selfBoardScore - opponentBoardScore;
}
