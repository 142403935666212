import { Board } from '../../Board';
import { Point } from '../../Point';
import { PieceKey, PieceEntity } from '../Piece';
import { Knight } from './Knight';

export class Camel extends Knight {
  key = PieceKey.Camel;
  moves: Point[] = [
    new Point(-3, -1),
    new Point(-3, 1),
    new Point(-1, -3),
    new Point(-1, 3),
    new Point(1, -3),
    new Point(1, 3),
    new Point(3, -1),
    new Point(3, 1),
  ];

  constructor(data: Partial<PieceEntity>, board: Board) {
    super(data, board);
  }

  override clone(board?: Board): Camel {
    return new Camel(this.toJSON(), board ?? this.board);
  }
}
