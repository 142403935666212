import * as Sentry from '@sentry/react';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Routes } from './Routes';
import { Environment } from './utils/constants';

if (
  import.meta.env.VITE_ENV === Environment.Development ||
  import.meta.env.VITE_ENV === Environment.Production
) {
  Sentry.init({
    dsn: 'https://6428e549263d69494476d22463518682@o4506934869753856.ingest.us.sentry.io/4506936161927168',
    environment: import.meta.env.VITE_ENV,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.feedbackIntegration({
        colorScheme: 'light',
        showBranding: false,

        // Custom copy
        buttonLabel: 'Send us feedback!',
        submitButtonLabel: 'Submit Feedback',
        formTitle: 'Send us feedback',
        namePlaceholder: 'Magnus Carlsen',
        messagePlaceholder:
          'What can we do better? Your bug reports and suggestions make Pixie Chess better.',
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/api\.pxidev\.net/],
    // Session Replay
    replaysSessionSampleRate:
      import.meta.env.VITE_ENV === Environment.Production ? 0.1 : 0.5, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <Routes />,
  </StrictMode>,
);
