import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../components/Button';
import { Input } from '../components/Input';
import { useStore } from '../store';
import { login } from '../utils/auth';
import { readLocalStorage, writeLocalStorage } from '../utils/localStorage';

function Login() {
  const navigate = useNavigate();
  const { redirectLink } = useStore();
  const [password, updatePassword] = useState('');

  const submitPassword = useCallback(
    async (password?: string) => {
      if (!password) {
        return;
      }

      const success = await login(password);
      if (success) {
        writeLocalStorage('demoPassword', password);
        navigate(redirectLink || '/invite');
      }
    },
    [navigate, redirectLink],
  );

  useEffect(() => {
    const password = readLocalStorage('demoPassword') ?? undefined;
    submitPassword(password);
  }, [submitPassword]);

  return (
    <>
      <div className="flex w-full max-w-[700px] flex-col items-center justify-center gap-10">
        <form
          style={{ display: 'contents' }}
          onSubmit={(e) => {
            e.preventDefault();
            submitPassword(password);
          }}
        >
          <Input
            value={password}
            onChange={updatePassword}
            inputPlaceholder="Enter password"
            isPassword={true}
          />
          <Button type="submit">Login</Button>
        </form>
      </div>
      <div className="fixed bottom-0 p-24 text-center text-3xl text-pixie-gray-2">
        We rotate passwords every so often. If you're having trouble logging in,
        please reach out to Josh at 628-219-6377.
      </div>
    </>
  );
}

export default Login;
