import { GameStatus } from 'pixie-dust';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createInviteLink } from '../../api/invite';
import { InviteLinkInput } from '../../components/InviteLinkInput';
import { useStore } from '../../store';
import { getInviteLink } from '../../utils/invite';

function CreateInvite() {
  const [inviteLink, setInviteLink] = useState<string>('');
  const userId = useStore((state) => state.userId);
  const game = useStore((state) => state.game);
  const setGame = useStore((state) => state.setGame);
  const isAuthenticated = useStore((state) => state.isAuthenticated);
  const navigate = useNavigate();
  const createdInviteLink = useRef(false); // Prevent creating multiple invite links during async call

  useEffect(() => {
    (async function () {
      if (!isAuthenticated || createdInviteLink.current) return;
      createdInviteLink.current = true;

      const { gameId: id } = await createInviteLink(userId);
      setInviteLink(getInviteLink(id));
      setGame(null);
      if (game?.status === GameStatus.PieceSelection) {
        navigate(`/game/${id}`);
      } else {
        navigate(`/invite/${id}`, { replace: true });
      }
    })();
  }, [game?.status, userId, isAuthenticated, navigate, setGame]);

  return (
    <div className="flex w-full items-center justify-center">
      <InviteLinkInput inviteLink={inviteLink} />
    </div>
  );
}

export default CreateInvite;
