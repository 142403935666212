import { Point } from '../../Point';

export function tweenPoints(from: Point, to: Point): Point[] | null {
  const points = [];
  const dxAbs = Math.abs(to.x - from.x);
  const dyAbs = Math.abs(to.y - from.y);
  const dxNorm = Math.sign(to.x - from.x);
  const dyNorm = Math.sign(to.y - from.y);

  let curr = from.clone();
  while (!curr.equals(to)) {
    curr = curr.add(new Point(dxNorm, dyNorm));
    if (
      Math.abs(curr.x - from.x) > dxAbs ||
      Math.abs(curr.y - from.y) > dyAbs
    ) {
      return null;
    }

    if (!curr.equals(to)) {
      points.push(curr);
    }
  }

  return points;
}
