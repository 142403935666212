import { Board } from '../../Board';
import { Move } from '../../Move';
import { Point } from '../../Point';
import { PieceEntityWithHasMoved, PieceKey } from '../Piece';
import { isRook, Rook } from '../rooks/Rook';
import { UndirectionedPiece } from '../UndirectionedPiece';

export class King extends UndirectionedPiece {
  hasMoved: boolean = false;
  key = PieceKey.King;
  moves: Point[] = [
    new Point(-1, -1),
    new Point(-1, 0),
    new Point(-1, 1),
    new Point(0, -1),
    new Point(0, 1),
    new Point(1, -1),
    new Point(1, 0),
    new Point(1, 1),
  ];

  constructor(data: Partial<PieceEntityWithHasMoved>, board: Board) {
    super(data, board);
    this.hasMoved = data.hasMoved ?? false;
  }

  override clone(board?: Board): King {
    return new King(this.toJSON(), board ?? this.board);
  }

  override getMovementPoints(debug = false): Point[] {
    const points = super.getMovementPoints();

    // Castling
    if (!this.hasMoved && !this.board.isPlayerInCheck(this.player)) {
      const unmovedRooks: Rook[] = [
        new Point(0, this.board.getFirstRank(this.player)),
        new Point(this.board.width - 1, this.board.getFirstRank(this.player)),
      ]
        .map((point) => this.board.getPieceAtPoint(point))
        .filter(
          (piece) => !!piece && isRook(piece) && !piece.hasMoved,
        ) as Rook[];

      for (const rook of unmovedRooks) {
        let isCastlingValid = true;
        const dx = Math.sign(rook.point.x - this.point.x);

        // Check if castling is blocked
        const point = new Point(this.point.x + dx, this.point.y);
        while (point.x !== rook.point.x) {
          if (this.board.getPieceAtPoint(point)) {
            isCastlingValid = false;
            break;
          }

          point.x += dx;
        }

        if (!isCastlingValid) {
          continue;
        }

        // Check if king moves through or ends in check
        for (let i = 1; i <= 2; i++) {
          const point = new Point(this.point.x + dx * i, this.point.y);
          if (
            this.board.isPointAttackedByPlayer(
              point,
              Board.getOtherPlayer(this.player),
            )
          ) {
            isCastlingValid = false;
            break;
          }
        }

        if (isCastlingValid) {
          points.push(new Point(this.point.x + 2 * dx, this.point.y));
        }
      }
    }

    return points;
  }

  getLegalPoints(debug = false): Point[] {
    const legalPoints = this.getMovementPoints(debug).filter((p) => {
      const isPossible =
        this.canAttackPoint(p) &&
        !this.board.doesMovePutSelfInCheck(
          Move.to(this, p, { simulated: true }),
        );

      // Castling is validated in getMovementPoints, so if it exists, it's legal
      const castling = Math.abs(p.x - this.point.x) === 2;
      return isPossible || castling;
    });
    debug &&
      console.info(
        `Legal points for ${this.key} at ${this.point.toNotation()}:`,
        legalPoints.map((p) => p.toNotation()),
      );
    return legalPoints;
  }

  override onMoveEnd(move: Move): void {
    this.hasMoved = true;

    // Castling
    const dx = move.from.x - move.to.x;
    if (
      !move.opts.originMove &&
      move.from.subtract(move.to).abs().equals(new Point(2, 0))
    ) {
      const rookX = dx > 0 ? 0 : this.board.width - 1;
      const rook = this.board.forceGetPieceAtPoint(
        new Point(rookX, move.from.y),
      );
      this.board.forceMovePiece(
        Move.to(rook, move.to.add(new Point(Math.sign(dx), 0)), {
          originMove: move,
        }),
      );
    }
  }

  override toJSON(): PieceEntityWithHasMoved {
    return {
      ...super.toJSON(),
      hasMoved: this.hasMoved,
    };
  }
}
