import { Board } from '../../Board';
import { Move } from '../../Move';
import { PieceKey } from '../Piece';
import { isPawn } from './Pawn';
import { PromotedPiece, PromotedPieceEntity } from './PromotedPiece';

export class Ooze extends PromotedPiece {
  _key = PieceKey.Ooze;

  constructor(
    data: Partial<PromotedPieceEntity> & { key: string },
    board: Board,
  ) {
    super(data, board);

    // // TODO: figure out how to make this work
    // return new Proxy(this, {
    //   get: (target: Ooze, prop) => {
    //     return target.piece[prop as keyof PieceEntity];
    //   },
    //   set: (target: Ooze, prop, value) => {
    //     target.piece[prop as string] = value;
    //     return true;
    //   },
    // });
  }

  override clone(board?: Board): Ooze {
    return new Ooze(this.toJSON(), board ?? this.board);
  }

  override emitRequiredUserInputsForMove(move: Move) {
    if (
      this.piece.key === PieceKey.Pawn &&
      this.board.getPieceAtPoint(move.to)
    ) {
      return true;
    }

    return super.emitRequiredUserInputsForMove(move);
  }

  get canBeEnPassanted(): boolean {
    return isPawn(this.piece) ? this.piece.canBeEnPassanted : false;
  }

  override onMoveEnd(move: Move): void {
    if (isPawn(this.piece)) {
      this.piece.hasMoved = true;
      if (
        move.to.y === this.board.getLastRank(this.player) &&
        move.opts?.promoteTo
      ) {
        this.promoteTo(
          {
            subKey: move.opts.promoteTo,
            key: move.opts.promoteTo,
            id: this.id,
            player: this.player,
            point: move.to.toJSON(),
          },
          this.board,
        );
      } else if (Math.abs(move.to.y - move.from.y) === 2) {
        // Set up for en passant
        this.piece.canBeEnPassanted = true;
      }

      return;
    }

    super.onMoveEnd(move);
  }

  // TODO(aki): figure out how to move this logic to onCapture
  override onMoveStart(move: Move): void {
    const piece = this.board.getPieceAtPoint(move.to)?.clone();
    super.onMoveStart(move);
    if (piece && piece.player !== this.player && piece.canBeCaptured) {
      this.promoteTo(piece.toJSON(), this.board);
    }
  }
}
