import { Board } from '../../Board';
import { Events } from '../../Game';
import { Move } from '../../Move';
import { Point } from '../../Point';
import { Player } from '../../constants';
import { Piece, PieceEntity, PieceKey } from '../Piece';
import { PromotedPiece } from './PromotedPiece';

export type PawnEntity = PieceEntity & {
  canBeEnPassanted?: boolean;
  hasMoved?: boolean;
};

export class Pawn extends Piece {
  canBeEnPassanted: boolean;
  key = PieceKey.Pawn;
  hasMoved: boolean;

  constructor(data: Partial<PawnEntity>, board: Board) {
    super(data, board);
    this.canBeEnPassanted = data.canBeEnPassanted ?? false;
    this.hasMoved = data.hasMoved ?? false;
  }

  override clone(board?: Board): Pawn {
    return new Pawn(this.toJSON(), board ?? this.board);
  }

  override emitRequiredUserInputsForMove(move: Move) {
    if (
      move.to.y === this.board.getLastRank(this.player) &&
      !move.opts?.promoteTo
    ) {
      // Promote to option required.
      // Return false and trigger event.
      this.board.emitter.emit(Events.Promotion, { move, piece: this });
      return false;
    }

    return true;
  }

  override getCapturePoints(): Point[] {
    const direction = Board.getDirection(this.player);
    const points = [
      new Point(this.point.x - 1, this.point.y + direction),
      new Point(this.point.x + 1, this.point.y + direction),
    ].filter((p) => this.board.canPointBeAttackedByPlayer(p, this.player));
    const enPassantPoint = this.getEnPassantPoint();
    return enPassantPoint ? [...points, enPassantPoint] : points;
  }

  override getMovementPoints(): Point[] {
    const points: Point[] = [];
    const direction = Board.getDirection(this.player);

    // Move forward if unblocked
    const forward = new Point(this.point.x, this.point.y + direction);
    const isBlocked = !!this.board.getPieceAtPoint(forward);
    if (this.board.containsPoint(forward) && !isBlocked) {
      points.push(forward);
    }

    // Move forward two spaces if unmoved and unblocked
    if (!this.hasMoved) {
      const doubleForward = new Point(
        this.point.x,
        this.point.y + 2 * direction,
      );
      if (
        this.board.containsPoint(doubleForward) &&
        !isBlocked &&
        !this.board.getPieceAtPoint(doubleForward)
      ) {
        points.push(doubleForward);
      }
    }

    return points;
  }

  override onCaptured(move: Move): void {
    super.onCaptured(move);
    this.canBeEnPassanted = false;
  }

  override onEverySelfTurnStart(turn: Player) {
    this.canBeEnPassanted = false;
  }

  override onMoveEnd(move: Move) {
    const hasMovedBefore = this.hasMoved;

    this.hasMoved = true;
    if (move.to.y === this.board.getLastRank(this.player)) {
      // Handle promotion
      if (!move.opts?.promoteTo) {
        // Return here rather than error so show tile as possible move.
        // Dry run should trigger promotion dialogue
        return;
      }

      const tile = this.board.getTile(move.to);
      if (!tile) {
        throw new Error('Invalid point');
      }

      const promotedPiece = new PromotedPiece(
        {
          subKey: move.opts.promoteTo,
          key: move.opts.promoteTo,
          id: this.id,
          player: this.player,
          point: move.to.toJSON(),
        },
        this.board,
      );
      this.board.removePiece(this);
      this.board.placePieceAtPoint(promotedPiece, move.to);
    } else if (
      !hasMovedBefore &&
      move.to.subtract(move.from).abs().equals(new Point(0, 2))
    ) {
      // Set up for en passant
      this.canBeEnPassanted = true;
    }
  }

  override onMoveStart(move: Move) {
    super.onMoveStart(move);

    // En passant
    const enPassant = this.getEnPassantPoint();
    if (enPassant?.equals(move.to)) {
      const enPassantCapture = enPassant.clone();
      enPassantCapture.y += Board.getOtherDirection(this.player);
      if (this.board.getPieceAtPoint(enPassantCapture)) {
        this.captureAtPoint(enPassantCapture);
      }
    }
  }

  override toJSON(): PawnEntity {
    return {
      ...super.toJSON(),
      hasMoved: this.hasMoved,
      canBeEnPassanted: this.canBeEnPassanted,
    };
  }

  protected getEnPassantPoint(): Point | null {
    const pawns = [-1, 1]
      .map((x) => this.board.getPieceAtPoint(this.point.add(new Point(x, 0))))
      .filter((p) => {
        return (
          !!p &&
          isPawn(p) &&
          p.canBeCaptured &&
          p.canBeEnPassanted &&
          p.player !== this.player
        );
      }) as Pawn[];

    if (pawns.length === 0) {
      return null;
    } else if (pawns.length > 1) {
      throw new Error('More than one pawn can be en passanted');
    } else {
      const pawn = pawns[0];
      const direction = Board.getDirection(this.player);
      return new Point(pawn.point.x, pawn.point.y + direction);
    }
  }
}

export function isPawn(p: Piece): p is Pawn {
  return p.subKey === PieceKey.Pawn;
}
