import { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useStore } from '../store';
import { login } from './auth';

export function PrivateRoute() {
  const { setIsAuthenticated, isAuthenticated } = useStore();

  useEffect(() => {
    (async () => {
      try {
        const res = await login();
        setIsAuthenticated(res);
      } catch (error) {
        console.error('Login failed:', error);
        setIsAuthenticated(false);
      }
    })();
  }, [setIsAuthenticated]);

  return isAuthenticated === null ? null : isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to="/login" />
  );
}
