import classNames from 'classnames';
import { Piece, Point } from 'pixie-dust';
import React from 'react';
import { useStore } from '../../store';
import { ClassName } from '../../utils/constants';
import { getPieceStyles } from '../../utils/pieces';

interface TileProps {
  className?: string | ((point: Point, piece?: Piece) => string);
  isPossible: boolean;
  piece?: Piece;
  point: Point;
  onMouseClick?: (e: React.MouseEvent, point: Point, piece?: Piece) => void;
  onMouseDown?: (e: React.MouseEvent, point: Point, piece?: Piece) => void;
  onMouseEnter?: (e: React.MouseEvent, point: Point, piece?: Piece) => void;
  onMouseLeave?: (e: React.MouseEvent, point: Point, piece?: Piece) => void;
}

const TileUnmemo = React.forwardRef<HTMLDivElement, TileProps>(
  (
    {
      className,
      isPossible,
      onMouseClick,
      onMouseDown,
      onMouseEnter,
      onMouseLeave,
      piece,
      point,
    }: TileProps,
    ref,
  ) => {
    const player = useStore((state) => state.player);
    const computedClassName = React.useMemo(() => {
      return typeof className === 'function'
        ? className(point, piece)
        : className;
    }, [className, point, piece]);

    const styles = React.useMemo<React.CSSProperties>(() => {
      return getPieceStyles(piece?.toJSON(), false);
    }, [piece]);

    return (
      <div
        ref={ref}
        className={classNames('absolute size-1/8 border-none outline-none', {
          [ClassName.PossibleCapture]:
            isPossible && piece && piece.player !== player,
          [ClassName.PossibleMove]: isPossible && !piece,
          [computedClassName ?? '']: computedClassName,
        })}
        key={point.toString()}
        onClick={(e) => {
          e.stopPropagation();
          onMouseClick?.(e, point, piece);
        }}
        onMouseDown={(e) => {
          e.stopPropagation();
          onMouseDown?.(e, point, piece);
        }}
        onMouseEnter={(e) => {
          e.stopPropagation();
          onMouseEnter?.(e, point, piece);
        }}
        onMouseLeave={(e) => {
          e.stopPropagation();
          onMouseLeave?.(e, point, piece);
        }}
        style={{
          cursor: piece || isPossible ? 'pointer' : 'default',
          transform: `translate(${point.x * 100}%, ${point.y * 100}%)`,
        }}
      >
        <div
          className="size-full pb-[7.5%]" // padding-bottom dictates offset of piece image
          style={styles}
        />
      </div>
    );
  },
);

export const Tile = React.memo(TileUnmemo);
