import { Board } from '../../Board';
import { Point } from '../../Point';
import { PieceKey, PieceEntity } from '../Piece';
import { UndirectionedPiece } from '../UndirectionedPiece';

export class Knight extends UndirectionedPiece {
  key = PieceKey.Knight;
  moves: Point[] = [
    new Point(-2, -1),
    new Point(-2, 1),
    new Point(-1, -2),
    new Point(-1, 2),
    new Point(1, -2),
    new Point(1, 2),
    new Point(2, -1),
    new Point(2, 1),
  ];

  constructor(data: Partial<PieceEntity>, board: Board) {
    super(data, board);
  }

  override clone(board?: Board): Knight {
    return new Knight(this.toJSON(), board ?? this.board);
  }
}
