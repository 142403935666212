import { Board } from '../../Board';
import { Point } from '../../Point';
import { toArray } from '../array';

export interface GetPointsFromDirectionsWithPhasingOpts {
  includeFirstCapture: (didPhase: boolean) => boolean;
  phaseThroughOpponentPieces: boolean;
  phaseThroughSelfPieces: boolean;
  range: number;
}

export function getPointsFromDirectionsWithPhasing(
  point: Point,
  deltas: Point | Point[],
  board: Board,
  userOpts?: Partial<
    | GetPointsFromDirectionsWithPhasingOpts
    | {
        includeFirstCapture: boolean;
      }
  >,
): Point[] {
  deltas = toArray(deltas);

  userOpts = userOpts ?? {};
  userOpts.includeFirstCapture = coerceToFunction(
    userOpts?.includeFirstCapture ?? true,
  );
  const opts: GetPointsFromDirectionsWithPhasingOpts = {
    includeFirstCapture: () => true,
    phaseThroughOpponentPieces: false,
    phaseThroughSelfPieces: false,
    range: Infinity,
    ...(userOpts as Partial<GetPointsFromDirectionsWithPhasingOpts>),
  };

  const points = [];
  const player = board.getPieceAtPoint(point)?.player;

  if (player === undefined) {
    throw new Error('Must provide player or point with a piece.');
  }

  for (const direction of deltas) {
    let didPhase = false;
    let curr = point.clone();
    for (let i = 0; i < opts.range; i++) {
      curr = curr.add(direction);
      if (!board.containsPoint(curr)) {
        break;
      }

      const piece = board.getPieceAtPoint(curr);
      if (piece) {
        if (!opts.phaseThroughSelfPieces && piece.player === player) {
          break;
        }

        if (
          !opts.phaseThroughOpponentPieces &&
          piece.player === Board.getOtherPlayer(player)
        ) {
          if (opts.includeFirstCapture(didPhase)) {
            points.push(curr);
          }

          break;
        }

        didPhase = true;
      }

      points.push(curr);
    }
  }

  return points;
}

const coerceToFunction = <T>(val: T | ((...args: any[]) => T)) =>
  typeof val === 'function' ? val : () => val as T;
