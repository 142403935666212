import { Board } from '../../Board';
import { Point } from '../../Point';
import { Player } from '../../constants';
import { toArray } from '../array';

export function getPointsFromDirections(
  point: Point,
  deltas: Point | Point[],
  board: Board,
  range = Infinity,
  includeFirstCapture = true,
  pointPlayer?: Player,
): Point[] {
  deltas = toArray(deltas);

  const points = [];
  const player = pointPlayer ?? board.getPieceAtPoint(point)?.player;

  if (player === undefined) {
    throw new Error('Must provide player or point with a piece.');
  }

  for (const direction of deltas) {
    let curr = point.clone();
    for (let i = 0; i < range; i++) {
      curr = curr.add(direction);
      if (!board.containsPoint(curr)) {
        break;
      }

      const piece = board.getPieceAtPoint(curr);
      if (piece) {
        if (
          piece.player === Board.getOtherPlayer(player) &&
          includeFirstCapture
        ) {
          points.push(curr);
        }

        break;
      }

      points.push(curr);
    }
  }

  return points;
}
