import { AbilityName, Game, Move, Piece, isAbilityPiece } from 'pixie-dust';
import { useCallback } from 'react';
import { useStore } from '../store';
import { getPieceAbilityInfo } from '../utils/pieces';
import { Button } from './Button';
import { Tooltip, TooltipContent, TooltipTrigger } from './ToolTip';

type AbilityButtonProps = {
  abilityName?: AbilityName;
  children: React.ReactNode;
  game: Game | null;
  onTrigger: (move: Move) => void;
  piece: Piece;
};

export function AbilityTrigger({
  abilityName,
  children,
  game,
  onTrigger,
  piece,
}: AbilityButtonProps) {
  const abilityInfo = getPieceAbilityInfo(piece);
  const loading = useStore((state) => state.abilityLoading);

  const triggerAbility = useCallback(() => {
    if (!isAbilityPiece(piece) || !game?.canUseAbility(piece, null)) {
      return;
    }

    // TODO: is this the best way to abstract translating the piece?
    const ability = piece.abilities.list()[0];

    // TODO: Add this as an entry point to Game object
    const move = Move.to(piece, null, { ability }).translate(
      game?.translatePoint,
    );
    if (move) {
      onTrigger(move);
    }
  }, [piece, onTrigger, game]);

  if (!abilityInfo) return null;
  return (
    <Tooltip>
      <TooltipTrigger asChild>{children}</TooltipTrigger>
      <TooltipContent className="max-w-[350px] border border-black bg-white p-3 text-4xl text-black">
        <p className="mb-2">{abilityInfo.description}</p>
        <Button
          disabled={loading}
          onClick={triggerAbility}
          alt
          className="flex items-center gap-2"
        >
          <span className="text-xl">{abilityInfo.icon}</span>
          {abilityInfo.cta}
        </Button>
      </TooltipContent>
    </Tooltip>
  );
}
