import { cloneDeep } from 'lodash';
import { Effect } from './Effect';
import { Move } from './Move';
import { Point, PointEntity } from './Point';
import { BoardEntity } from './Board';

export interface HistoryMoveEntity {
  from: PointEntity;
  to: PointEntity;
  pieceId: number;
  pieceKey: string;
  effects?: Effect[];
  board?: BoardEntity;
}

export class HistoryMove {
  effects?: Effect[];
  from: Point;
  pieceId: number;
  pieceKey: string;
  to: Point;

  static fromJSON(move: HistoryMoveEntity): HistoryMove {
    return new HistoryMove(
      Point.fromJSON(move.from),
      Point.fromJSON(move.to),
      move.pieceId,
      move.pieceKey,
      move.effects,
    );
  }

  static fromMove(move: Move, effects?: Effect[]): HistoryMove {
    return new HistoryMove(
      move.from.clone(),
      move.to.clone(),
      move.pieceId,
      move.pieceKey,
      effects,
    );
  }

  constructor(
    from: Point,
    to: Point,
    pieceId: number,
    pieceKey: string,
    effects?: Effect[],
  ) {
    this.from = from;
    this.to = to;
    this.pieceId = pieceId;
    this.pieceKey = pieceKey;
    this.effects = effects;
  }

  clone(): HistoryMove {
    return HistoryMove.fromJSON(this.toJSON());
  }

  toJSON() {
    return {
      from: this.from.toJSON(),
      to: this.to.toJSON(),
      pieceId: this.pieceId,
      pieceKey: this.pieceKey,
      effects: this.effects?.map((effect) => cloneDeep(effect)),
    };
  }
}
