import {
  Board,
  Piece,
  PieceKey,
  Player,
  getCapturedPiecesByPlayer,
  getPieceScore,
  getScoreDeltaForPlayer,
  getSubKeyFromKey,
  isPixiePiece,
} from 'pixie-dust';
import { useState } from 'react';
import { Color } from '../../utils/constants';
import { getPieceHeight, getPieceStyles } from '../../utils/pieces';

interface CapturedPiecesProps {
  pieces: Piece[];
  player: Player | null;
}

export function CapturedPieces({ pieces, player }: CapturedPiecesProps) {
  const [hoveredPieceIndex, setHoveredPieceIndex] = useState<number | null>(
    null,
  );

  if (player === null) {
    return null;
  }

  const onCapturedPieceMouseEnter = (i: number) => {
    setHoveredPieceIndex(i);
  };

  const onCapturedPieceMouseLeave = () => {
    setHoveredPieceIndex(null);
  };

  const capturedPieceData = getCapturedPiecesByPlayer(pieces, player)
    .map((p) => p.toJSON())
    .sort((a, b) => {
      // Sort by:
      // 1. Piece score descending
      // 2. Piece subkey alphabetically
      // 3. Pixie pieces first
      // 4. Piece key alphabetically
      return (
        getPieceScore(b.key) - getPieceScore(a.key) ||
        a.subKey.localeCompare(b.subKey) ||
        +isPixiePiece(b) - +isPixiePiece(a) ||
        a.key.localeCompare(b.key)
      );
    });
  const score = getScoreDeltaForPlayer(pieces, Board.getOtherPlayer(player));

  return (
    player !== null && (
      <div className="ml-[-.15rem] mt-[-.55rem] flex h-10 select-none flex-wrap items-end justify-start">
        {capturedPieceData.map((piece, i) => {
          const isGroupedPiece =
            i > 0 &&
            getSubKeyFromKey(capturedPieceData[i - 1].key) ===
              getSubKeyFromKey(piece.key);
          return (
            <div
              className="h-10 w-[1.4rem]"
              key={piece.id}
              style={{
                ...getPieceStyles(piece),
                marginLeft: isGroupedPiece ? '-.6rem' : '0rem',
                transition: 'transform 0.05s ease',
                transform: `scale(${hoveredPieceIndex === i ? 1.33 : 1})`,
                transformOrigin: 'bottom',
              }}
              onMouseEnter={() => onCapturedPieceMouseEnter(i)}
              onMouseLeave={onCapturedPieceMouseLeave}
            ></div>
          );
        })}
        {score > 0 ? (
          <div
            className="ml-2 font-inter text-[1rem]"
            style={{
              color: Color.AppBackground,
              lineHeight: `${getPieceHeight(capturedPieceData[capturedPieceData.length - 1]?.key ?? PieceKey.Pawn) * 2}%`,
            }}
          >
            +{score}
          </div>
        ) : null}
      </div>
    )
  );
}
