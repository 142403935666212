import { Board } from '../../Board';
import { Move } from '../../Move';
import { Point } from '../../Point';
import { Player } from '../../constants';
import { getPieceFromData, getSubKeyFromKey } from '../../utils';
import { Piece, PieceEntity, PieceKey } from '../Piece';

/**
 * `PromotedPieceEntity` adds a piece property to the `PieceEntity` type that
 * contains the piece that was promoted to. `subKey` refers to the original
 * type of piece that was promoted from. This is used as a fallback when
 * piece is undefined (eg Ooze before it makes its first capture).
 */
export interface PromotedPieceEntity extends PieceEntity {
  piece?: PieceEntity;
}

/**
 * Once this piece is promoted, it will wrap the promoted piece in its `piece`
 * property and delegate all calls to it while taking on its `key` and `subKey`.
 * The original `key` and `subKey` are stored in `_key` and `_subKey`
 * respectively. For example, a pawn that has promoted to a queen will have the
 * following structure:
 *
 * ```
 * PromotedPiece {
 *   key: 'queen',
 *   subKey: 'queen',
 *   _key: 'promoted',
 *   _subKey: 'pawn',
 *   piece: {
 *     key: 'queen',
 *     subKey: 'queen',
 *   }
 * }
 * ```
 *
 * When `toJSON` is called, `_key` and `_subKey` are serialized as `key` and
 * `subKey`, and the wrapped piece's `key` and `subKey` are serialized as
 * `piece.key` and `piece.subKey`. If we were to serialize the above example,
 *
 * ```
 * PromotedPieceEntity {
 *   key: 'promoted',
 *   subKey: 'pawn',
 *   piece: {
 *     key: 'queen',
 *     subKey: 'queen',
 *   }
 * }
 * ```
 */
export class PromotedPiece extends Piece {
  piece!: Piece;
  _key = PieceKey.Promoted;
  _subKey = PieceKey.Pawn;

  constructor(
    data: Partial<PromotedPieceEntity> & { key: string },
    board: Board,
  ) {
    if (!data.subKey) {
      const key = data.key ?? data.subKey ?? 'piece';
      throw new Error(
        `Error creating promoted ${key}: missing data ${JSON.stringify(data)}`,
      );
    }

    super(data, board);
    this.promoteTo(data, board ?? this.board);
  }

  override canAttackPoint(point: Point): boolean {
    return this.piece.canAttackPoint(point);
  }

  override captureAtPoint(point: Point): Piece {
    return this.piece.captureAtPoint(point);
  }

  override clone(board?: Board): PromotedPiece {
    return new PromotedPiece(this.toJSON(), board ?? this.board);
  }

  override emitRequiredUserInputsForMove(move: Move): boolean {
    return this.piece.emitRequiredUserInputsForMove(move);
  }

  override getAllPoints(debug?: boolean): Point[] {
    return this.piece.getAllPoints(debug);
  }

  override getCapturePoints(): Point[] {
    return this.piece.getCapturePoints();
  }

  override getLegalPoints(): Point[] {
    return this.piece.getLegalPoints();
  }

  override getMovementPoints(): Point[] {
    return this.piece.getMovementPoints();
  }

  override getTag(): string {
    return this.piece.getTag();
  }

  override onCapture(move: Move): void {
    this.piece.onCapture(move);
  }

  override onCaptured(move: Move): void {
    this.piece.onCaptured(move);
    this.captured = true;
  }

  override onEveryOpponentTurnEnd(turn: Player): void {
    this.piece.onEveryOpponentTurnEnd(turn);
  }

  override onEveryOpponentTurnStart(turn: Player): void {
    this.piece.onEveryOpponentTurnStart(turn);
  }

  override onEverySelfTurnEnd(turn: Player): void {
    this.piece.onEverySelfTurnEnd(turn);
  }

  override onEverySelfTurnStart(turn: Player): void {
    this.piece.onEverySelfTurnStart(turn);
  }

  override onEveryTurnEnd(turn: Player): void {
    this.piece.onEveryTurnEnd(turn);
  }

  override onEveryTurnStart(turn: Player): void {
    this.piece.onEveryTurnStart(turn);
  }

  override onGameStart(): void {
    this.piece.onGameStart();
  }

  override onMoveEnd(move: Move): void {
    this.piece.onMoveEnd(move);
  }

  override onMoveStart(move: Move): void {
    this.piece.onMoveStart(move);
  }

  promoteTo(
    piece: Partial<PieceEntity> & { key: string },
    board?: Board,
  ): void {
    const data = {
      ...piece,
      captured: this.captured,
      id: this.id,
      player: this.player,
      point: this.point.toJSON(),
    };
    if (!data.subKey) {
      data.subKey = getSubKeyFromKey(data.key);
    }

    // If we are promoting to a wrapper piece, unwrap it and promote this to the
    // wrapped piece.
    // TODO: if we have another Ooze-like piece, we may not want to unwrap so
    // we can have them retain each other's abilities.
    if (isWrapperPieceEntity(data)) {
      this.promoteTo(data.piece ?? { key: data.subKey }, board);
      return;
    }

    this.piece = getPieceFromData(data, board ?? this.board);
    this.key = this.piece.key;
    this.subKey = getSubKeyFromKey(this.key); // TODO: should this be this.piece.subKey?
    this.canBeCaptured = this.piece.canBeCaptured;
  }

  override toJSON(): PromotedPieceEntity {
    const ret: PromotedPieceEntity = {
      ...super.toJSON(),
      key: this._key,
      subKey: this._subKey,
    };
    if (this.piece) {
      ret.piece = this.piece.toJSON();
    }

    return ret;
  }
}

export const isPromotedPieceEntity = (
  piece: Partial<PieceEntity>,
): piece is PromotedPieceEntity => {
  return piece.key === PieceKey.Promoted;
};

export const isWrapperPieceEntity = (
  piece: Partial<PieceEntity>,
): piece is PromotedPieceEntity => {
  return (
    piece.key === PieceKey.Ooze ||
    piece.key === PieceKey.Pinata ||
    piece.key === PieceKey.Promoted
  );
};
