import { Tooltip, TooltipContent, TooltipTrigger } from '.';

type SelectionPieceProps = {
  children: React.ReactNode;
  content: string;
  asChild?: boolean;
};

export function TooltipWrapper({
  children,
  content,
  asChild,
}: SelectionPieceProps) {
  return (
    <Tooltip>
      <TooltipTrigger asChild={asChild}>{children}</TooltipTrigger>
      <TooltipContent className="max-w-[300px] border border-black bg-white p-3 text-3xl text-black">
        {content}
      </TooltipContent>
    </Tooltip>
  );
}
