import type {} from '@redux-devtools/extension'; // required for devtools typing
import {
  ClientEvent,
  GameEntity,
  GameStatus,
  ServerEvent,
  findIndex,
} from 'pixie-dust';
import { io } from 'socket.io-client';
import { useStore } from '.';

export const socket = io(import.meta.env.VITE_WS_URL as string, {
  transports: ['websocket'],
});

export interface DefaultEventsMap {
  [event: string]: (...args: any[]) => void;
}

type SocketError = {
  event: ClientEvent;
  message: string;
  success: false;
};

type SocketUpdate =
  | {
      event: ClientEvent;
      game: GameEntity;
      success: true;
    }
  | {
      error: string;
      event: ClientEvent;
      success: false;
    };

socket.on(ServerEvent.Error, (error: SocketError) => {
  console.error('WS: received error', error);

  if (error.event === ClientEvent.Move) {
    throw new Error(`Error making move. ${error.message}`);
  }
});

socket.on(ServerEvent.Update, (update: SocketUpdate) => {
  console.info('WS: received update', update);
  if (!update.success) {
    return;
  }

  // Set game
  const store = useStore.getState();
  store.updateAbilityLoading(false);
  store.setGame(update.game);

  const userId = store.userId;
  const { playerIds, status } = update.game;
  const playerIdArray = Object.values(playerIds);
  // Set player. Player may change on rematch, so always update
  const player =
    findIndex(playerIdArray, (id) => id === userId) ??
    findIndex(playerIdArray, (id) => id === '');
  if (player !== null) {
    store.setPlayer(player);
  } else {
    console.error(
      `WS: received update for game not joined by user ${userId}`,
      update,
    );
  }
  if (update.event === ClientEvent.Connect) {
    // Set isConnected

    const isInGame = playerIdArray.includes(userId);
    const canJoinGame =
      playerIdArray.includes('') && status === GameStatus.Created;
    if (isInGame || canJoinGame) {
      store.setIsConnected(true);
    }
  }
});
