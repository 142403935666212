import classNames from 'classnames';
import {
  ClientEvent,
  GameStatus,
  Piece,
  Point,
  Substitution,
  getBasePieceById,
  isPixiePiece,
} from 'pixie-dust';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Board, BoardProps } from '../../components/Board';
import { Button } from '../../components/Button';
import { useGame } from '../../hooks/useGame';
import {
  TEST_USER_PIECES,
  UserPiece,
  getUserPieceUniqueId,
  useStore,
} from '../../store';
import '../../styles/piece-selection.css';
import { BoardId, ClassName } from '../../utils/constants';
import { getPieceHref } from '../../utils/pieces';
import { sleep } from '../../utils/sleep';
import { SelectedPiece } from './SelectedPiece';
import { getSubstitutionErrorMessage } from './getSubstitutionErrorMessage';

type PieceSubstitutionProps = {
  boardProps: BoardProps;
};

export function PieceSubstitution({ boardProps }: PieceSubstitutionProps) {
  // Note on naming convention: using "chosen" to denote pieces that have been
  // substituted onto the board, and "selected" for the piece that has been
  // clicked and is about to be substituted.
  const [chosenPieces, setChosenPieces] = useState<
    (UserPiece & { point: Point })[]
  >([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [hoveredPoint, setHoveredPoint] = useState<Point | null>(null);
  const [hoveredUserPiece, setHoveredUserPiece] = useState<UserPiece | null>(
    null,
  );
  const [substitutions, setSubstitutions] = useState<Substitution[]>([]);

  const { game } = useGame();
  const isFetchingUserPieces = useStore((state) => state.isFetchingUserPieces);
  const player = useStore((state) => state.player);
  const selectedPiece = useStore((state) => state.selectedPiece);
  const userId = useStore((state) => state.userId);
  const userPieces = useStore((state) => state.userPieces);
  const emit = useStore((state) => state.emit);
  const setIsFetchingUserPieces = useStore(
    (state) => state.setIsFetchingUserPieces,
  );
  const setSelectedPiece = useStore((state) => state.setSelectedPiece);
  const setUserPieces = useStore((state) => state.setUserPieces);

  const hoveredUserPiecePoint = useMemo(
    () =>
      chosenPieces.find(
        (p) =>
          hoveredUserPiece &&
          getUserPieceUniqueId(p) === getUserPieceUniqueId(hoveredUserPiece),
      )?.point,
    [chosenPieces, hoveredUserPiece],
  );
  const waitingForOtherPlayer =
    player != null &&
    game?.status === GameStatus.PieceSelection &&
    game.playerStatuses[player];

  useEffect(() => {
    (async () => {
      if (player == null) {
        setUserPieces([]);
        setIsFetchingUserPieces(false);
        return;
      }

      setIsFetchingUserPieces(true);
      await sleep(0);

      // TODO: use custom image URL when available
      setUserPieces(
        TEST_USER_PIECES.map((userPiece) => ({
          ...userPiece,
          player,
          image: getPieceHref(userPiece.key, player),
        })),
      );
      setIsFetchingUserPieces(false);
    })();
  }, [userId, player, setUserPieces, setIsFetchingUserPieces]);

  // Get body and add listener to unselect piece on click
  useEffect(() => {
    const body = document.querySelector('body');
    if (!body) {
      console.error('No body element found');
      return;
    }

    const unselectPiece = () => setSelectedPiece(null);
    body.addEventListener('click', unselectPiece);
    return () => body.removeEventListener('click', unselectPiece);
  }, [setSelectedPiece]);

  const onTileMouseClick = useCallback(
    (e: React.MouseEvent, point: Point, piece?: Piece) => {
      if (e.button !== 0) {
        return;
      }

      if (!game || player === null || waitingForOtherPlayer) {
        return;
      }

      const translatedPoint = game.translatePoint(point);

      // If no piece is selected, and a chosen piece on the board is clicked,
      // remove it from the board and replace it with the corresponding base
      // piece.
      if (!selectedPiece && piece && isPixiePiece(piece.toJSON())) {
        // Remove substitution if it exists
        setSubstitutions((prevSubstitutions) => {
          const newSubstitutions = prevSubstitutions.map((s) => s.clone());
          const index = newSubstitutions.findIndex((s) =>
            s.to.equals(translatedPoint),
          );
          if (index !== -1) {
            newSubstitutions.splice(index, 1);
          }

          return newSubstitutions;
        });

        // Remove from chosen piece list
        setChosenPieces((prevChosenPieces) => {
          return prevChosenPieces.filter(
            (p) => !p.point.equals(translatedPoint),
          );
        });

        // Replace piece on board with original base piece
        const newGame = game.clone();
        const basePiece = getBasePieceById(piece.id);
        newGame.substitute(new Substitution(basePiece.subKey, player, point));
        useStore.setState((state) => ({
          ...state,
          game: newGame.toJSON(),
        }));

        setErrorMessage(null);
      }

      if (!selectedPiece) {
        return;
      }

      setErrorMessage(null);

      const substitution = new Substitution(
        selectedPiece.key,
        player,
        point,
        selectedPiece.tokenId,
      );
      const { isLegal, error } = game.isSubstitutionLegal(substitution);
      if (!isLegal) {
        setErrorMessage(getSubstitutionErrorMessage(error!));
        console.error('Illegal substitution:', error);
        return;
      }

      const translatedSub = game.substitute(substitution);
      useStore.setState((state) => ({ ...state, game: game.clone().toJSON() }));
      setSubstitutions((prevSubstitutions) => {
        const newSubstitutions = prevSubstitutions.map((s) => s.clone());
        const index = newSubstitutions.findIndex((s) =>
          s.to.equals(translatedSub.to),
        );

        // Replace existing substitution or add new
        index !== -1
          ? (newSubstitutions[index] = translatedSub)
          : newSubstitutions.push(translatedSub);

        return newSubstitutions;
      });
      setChosenPieces((prevChosenPieces) => {
        const newChosenPieces = prevChosenPieces.filter(
          (p) => !p.point.equals(translatedPoint),
        );
        newChosenPieces.push({ ...selectedPiece, point: translatedPoint });
        return newChosenPieces;
      });

      // Set hovered point to null to prevent hover effect from triggering
      // instantly.
      setHoveredPoint(null);
    },
    [game, player, selectedPiece, waitingForOtherPlayer],
  );

  const onTileMouseEnter = useCallback(
    (_: React.MouseEvent, translatedPoint: Point) => {
      setHoveredPoint(translatedPoint);
    },
    [],
  );

  const onTileMouseLeave = useCallback(
    (_: React.MouseEvent, translatedPoint: Point) => {
      setHoveredPoint(null);
    },
    [setHoveredPoint],
  );

  const tileClassName = useCallback(
    (translatedPoint: Point, piece?: Piece) => {
      // Possible substitution
      const isPossibleSubstitution =
        piece &&
        piece?.subKey === (hoveredUserPiece ?? selectedPiece)?.subKey &&
        piece.player === player;

      return classNames({
        [ClassName.Hovered]:
          hoveredUserPiecePoint?.equals(translatedPoint) ||
          hoveredPoint?.equals(translatedPoint) ||
          false,
        [ClassName.PossibleSubstitution]:
          !hoveredUserPiecePoint && isPossibleSubstitution,
        [ClassName.SubstitutedPiece]: piece && isPixiePiece(piece.toJSON()),
      });
    },
    [
      hoveredPoint,
      hoveredUserPiece,
      hoveredUserPiecePoint,
      player,
      selectedPiece,
    ],
  );

  const empty = useCallback(() => {}, []);

  return (
    <div className="flex size-full max-w-[1200px] flex-col items-center justify-center gap-10">
      <div className="flex w-full flex-col gap-10 p-10 md:flex-row">
        <div className="flex-1">
          {isFetchingUserPieces && (
            <p className="text-5xl text-pixie-gray-2">Loading pieces...</p>
          )}
          {!isFetchingUserPieces && userPieces && userPieces.length > 0 && (
            <div className="grid grid-cols-auto-fit gap-5">
              {userPieces.map((piece, i) => {
                const userPiece = chosenPieces.find(
                  (p) =>
                    getUserPieceUniqueId(p) === getUserPieceUniqueId(piece),
                );
                const isDisabled = !!userPiece || waitingForOtherPlayer;
                return (
                  <SelectedPiece
                    className={isDisabled ? 'cursor-default' : 'cursor-pointer'}
                    key={i}
                    piece={piece}
                    onMouseEnter={() => {
                      const hoveredPoint =
                        userPiece?.point && game?.translate(userPiece.point);
                      setHoveredUserPiece(piece);
                      setHoveredPoint(hoveredPoint ?? null);
                    }}
                    onMouseLeave={() => {
                      setHoveredUserPiece(null);
                      setHoveredPoint(null);
                    }}
                  />
                );
              })}
            </div>
          )}
        </div>
        <div className="flex flex-col items-center justify-center">
          <div className="size-[320px] md:size-[440px] lg:size-[640px]">
            <Board
              {...boardProps}
              className="size-full"
              responsive={false}
              id={BoardId.PieceSelection}
              onTileMouseClick={onTileMouseClick}
              onTileMouseDown={empty} // Have to pass in empty fn, otherwise Board will use its own mousedown handler
              onTileMouseEnter={onTileMouseEnter}
              onTileMouseLeave={onTileMouseLeave}
              tileClassName={tileClassName}
            />
            {errorMessage && (
              <div className="mt-4 w-full text-center text-2xl text-pixie-red">
                {errorMessage}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex w-1/2 max-w-[700px] flex-col items-center justify-center gap-10">
        {!!waitingForOtherPlayer && (
          <p className="text-5xl text-pixie-gray-2">
            Waiting for other player...
          </p>
        )}
        <Button
          disabled={waitingForOtherPlayer}
          onClick={() => {
            if (!game) {
              return;
            }

            emit(ClientEvent.Substitute, game.id, userId, substitutions);
            setSubstitutions([]);
            setErrorMessage(null);
            setSelectedPiece(null);
          }}
        >
          {waitingForOtherPlayer ? 'Waiting' : 'Ready up'}
        </Button>
      </div>
    </div>
  );
}
