import { Board } from '../Board';
import { Point } from '../Point';
import { getPointsFromDirections, tweenPoints } from '../utils/movement';
import { Piece, PieceEntity } from './Piece';

export class DirectionedPiece extends Piece {
  directions!: Point[];
  range: number = Infinity;

  constructor(data: Partial<PieceEntity>, board: Board) {
    super(data, board);
  }

  // Doesn't check if current piece is pinned
  override canAttackPoint(point: Point): boolean {
    // Check if point is outside of range
    const dxAbs = Math.abs(point.x - this.point.x);
    const dyAbs = Math.abs(point.y - this.point.y);
    if (dxAbs > this.range || dyAbs > this.range) {
      return false;
    }

    // Check if point is contained in board
    if (!this.board.containsPoint(point)) {
      return false;
    }

    // Check if point is in line of sight given open board
    const dxNorm = Math.sign(point.x - this.point.x);
    const dyNorm = Math.sign(point.y - this.point.y);
    const delta = new Point(dxNorm, dyNorm);
    if (!this.directions.some((p) => p.equals(delta))) {
      return false;
    }

    // Check if piece at point, if any, is takeable
    const piece = this.board.getPieceAtPoint(point);
    const canBeCaptured =
      piece && piece.player !== this.player && piece.canBeCaptured;
    if (piece && !canBeCaptured) {
      return false;
    }

    // Check if there is an obstacle along the way
    const points = tweenPoints(this.point, point);
    return (
      points !== null && !points.some((p) => this.board.getPieceAtPoint(p))
    );
  }

  override getMovementPoints(): Point[] {
    return getPointsFromDirections(
      this.point,
      this.directions,
      this.board,
      this.range,
    );
  }
}
