import { Board } from '../../Board';
import { Move } from '../../Move';
import { Point } from '../../Point';
import { getDistanceToBoardEdge, getPointsFromDirections } from '../../utils';
import { PieceKey } from '../Piece';
import { Rook } from './Rook';

export class Sumo extends Rook {
  key = PieceKey.Sumo;

  override canAttackPoint(point2: Point): boolean {
    // Is point in line with sumo
    const direction = point2.subtract(this.point).normalize();
    if (!this.directions.some((d) => d.equals(direction))) {
      return false;
    }

    // Piece 1, the piece in front of piece 2
    const point1 = point2.subtract(direction);
    const piece1 = this.board.getPieceAtPoint(point1);

    // Piece 2, the piece to be taken
    const piece2 = this.board.getPieceAtPoint(point2);
    if (!piece2) {
      return false;
    }

    // If there is a piece behind it, piece 2 can't be taken
    const point3 = point2.add(direction);
    const piece3 = this.board.getPieceAtPoint(point3);
    if (piece3) {
      return false;
    }

    // Sumo must move at least one space to knock pieces back
    const points = getPointsFromDirections(this.point, direction, this.board);
    if (points.length < 2) {
      return false;
    }

    // Clear line of sight to piece or piece before it
    const endsAtPoint1 = piece1 && points[points.length - 1].equals(point1);
    const endsAtPoint2 = points[points.length - 1].equals(point2);
    if (!(endsAtPoint1 || endsAtPoint2)) {
      return false;
    }

    // Make sure piece 2 can be knocked off the board
    return (
      (piece1 && getDistanceToBoardEdge(point2, this.board) === 0) ||
      getDistanceToBoardEdge(point2, this.board) === 1
    );
  }

  override clone(board?: Board): Rook {
    return new Sumo(this.toJSON(), board ?? this.board);
  }

  override getMovementPoints(): Point[] {
    return getPointsFromDirections(
      this.point,
      this.directions,
      this.board,
      Infinity,
      false,
      this.player,
    );
  }

  // Move the piece (if any) that is one tile in the direction of the move two
  // tiles in that same direction OR two pieces one tile each in that direction.
  // Nothing should happen if there are three pieces. Pieces pushed off of the
  // board are considered captured.
  override onMoveEnd(move: Move): void {
    super.onMoveEnd(move);
    const direction = move.to.subtract(move.from).normalize();

    // Piece 1
    const point1 = move.to.add(direction);
    const piece1 = this.board.getPieceAtPoint(point1);
    if (!piece1) {
      return;
    }

    // Piece 2
    const point2 = move.to.add(direction.scale(2));
    const piece2 = this.board.getPieceAtPoint(point2);

    // Piece 3
    const point3 = move.to.add(direction.scale(3));
    const piece3 = this.board.getPieceAtPoint(point3);
    if (piece2 && piece3) {
      return;
    }

    // Bounds
    const isInBounds2 = this.board.containsPoint(point2);
    const isInBounds3 = this.board.containsPoint(point3);

    // Move piece 2
    if (piece2) {
      isInBounds3
        ? this.board.forceMovePiece(Move.to(piece2, point3))
        : this.captureAtPoint(point2);
    }

    // Move piece 1
    if (piece2) {
      this.board.forceMovePiece(Move.to(piece1, point2));
    } else if (!isInBounds2 || !isInBounds3) {
      this.captureAtPoint(point1);
    } else if (piece3) {
      this.board.forceMovePiece(Move.to(piece1, point2));
    } else {
      this.board.forceMovePiece(Move.to(piece1, point3));
    }
  }
}
