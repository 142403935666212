import { Point } from '../../Point';
import { getPointsFromDirectionsWithBouncing } from '../../utils';
import { PieceKey } from '../Piece';
import { Bishop } from './Bishop';

export class Bouncer extends Bishop {
  key = PieceKey.Bouncer;

  override canAttackPoint(point: Point): boolean {
    // TODO: is there a more efficient way to determine if a point is in the
    // list of movement points?
    return this.getMovementPoints().some((p) => p.equals(point));
  }

  override clone() {
    return new Bouncer(this.toJSON(), this.board);
  }

  override getMovementPoints(): Point[] {
    return getPointsFromDirectionsWithBouncing(
      this.point,
      this.directions,
      this.board,
      this.player,
    );
  }
}
