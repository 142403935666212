import { Board } from '../../Board';
import { Point } from '../../Point';
import { PieceKey } from '../Piece';
import { Pawn } from './Pawn';

export class PawnWithKnife extends Pawn {
  key = PieceKey.PawnWithKnife;

  override clone(board?: Board): PawnWithKnife {
    return new PawnWithKnife(this.toJSON(), board ?? this.board);
  }

  override getCapturePoints(): Point[] {
    const points = super.getCapturePoints();

    // Can't capture two tiles away if blocked
    const direction = this.point.x < this.board.width / 2 ? 1 : -1;
    const blocker = this.board.getPieceAtPoint(
      new Point(
        this.point.x + direction,
        this.point.y + Board.getDirection(this.player),
      ),
    );
    if (blocker) {
      return points;
    }

    // Capture two tiles diagonally towards board center
    const capturePoint = new Point(
      this.point.x + direction * 2,
      this.point.y + Board.getDirection(this.player) * 2,
    );
    if (this.board.canPointBeAttackedByPlayer(capturePoint, this.player)) {
      points.push(capturePoint);
    }

    return points;
  }
}
