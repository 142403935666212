import { Board } from '../../Board';
import { Move } from '../../Move';
import { Point } from '../../Point';
import { PieceKey, PieceEntity } from '../Piece';
import { Bishop } from './Bishop';

export interface MarauderEntity extends PieceEntity {
  range: number;
}

export class Marauder extends Bishop {
  directions: Point[] = [
    new Point(-1, -1),
    new Point(-1, 0),
    new Point(-1, 1),
    new Point(0, -1),
    new Point(0, 1),
    new Point(1, -1),
    new Point(1, 0),
    new Point(1, 1),
  ];
  key = PieceKey.Marauder;
  range: number = 1;

  constructor(data: Partial<MarauderEntity>, board: Board) {
    super(data, board);
    this.range = data.range ?? this.range;
  }

  override clone() {
    return new Marauder(this.toJSON(), this.board);
  }

  override onCapture(move: Move): void {
    super.onCapture(move);
    this.range += 2;
  }

  override toJSON() {
    return {
      ...super.toJSON(),
      range: this.range,
    };
  }
}
