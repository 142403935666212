// TODO: use zod to validate res
export async function fetchJSON<T>(
  input: RequestInfo | URL,
  init?: RequestInit | undefined,
): Promise<T> {
  const res = await fetch(input, init);
  if (!res.ok) {
    const error = await res.text();
    throw new Error(error);
  }

  return res.json();
}
