import {
  Piece,
  PieceEntity,
  PieceKey,
  Player,
  getPieceTag,
  getSubKeyFromKey,
  type Ooze,
  type Pinata,
  type PromotedPiece,
} from 'pixie-dust';
import wbishop from '../assets/pieces/0bishop.svg';
import wbouncer from '../assets/pieces/0bouncer.svg';
import wcamel from '../assets/pieces/0camel.svg';
import wepee from '../assets/pieces/0epee.svg';
import wgolden from '../assets/pieces/0golden.svg';
import wiron from '../assets/pieces/0iron.svg';
import wking from '../assets/pieces/0king.svg';
import wknight from '../assets/pieces/0knight.svg';
import wmarauder from '../assets/pieces/0marauder.svg';
import wpawn from '../assets/pieces/0pawn.svg';
import wpawnWithKnife from '../assets/pieces/0pawnWithKnife.svg';
import wphase from '../assets/pieces/0phase.svg';
import wpilgrim from '../assets/pieces/0pilgrim.svg';
import wpinata from '../assets/pieces/0pinata.svg';
import wqueen from '../assets/pieces/0queen.svg';
import wrocketman from '../assets/pieces/0rocketman.svg';
import wrook from '../assets/pieces/0rook.svg';
import wsumo from '../assets/pieces/0sumo.svg';
import bbishop from '../assets/pieces/1bishop.svg';
import bbouncer from '../assets/pieces/1bouncer.svg';
import bcamel from '../assets/pieces/1camel.svg';
import bepee from '../assets/pieces/1epee.svg';
import bgolden from '../assets/pieces/1golden.svg';
import biron from '../assets/pieces/1iron.svg';
import bking from '../assets/pieces/1king.svg';
import bknight from '../assets/pieces/1knight.svg';
import bmarauder from '../assets/pieces/1marauder.svg';
import bpawn from '../assets/pieces/1pawn.svg';
import bpawnWithKnife from '../assets/pieces/1pawnWithKnife.svg';
import bphase from '../assets/pieces/1phase.svg';
import bpilgrim from '../assets/pieces/1pilgrim.svg';
import bpinata from '../assets/pieces/1pinata.svg';
import bqueen from '../assets/pieces/1queen.svg';
import brocketman from '../assets/pieces/1rocketman.svg';
import brook from '../assets/pieces/1rook.svg';
import bsumo from '../assets/pieces/1sumo.svg';
import { UserPiece } from '../store';

type Denominator<A, B> = Pick<
  A,
  {
    [K in keyof A & keyof B]: A[K] extends B[K]
      ? B[K] extends A[K]
        ? K
        : never
      : never;
  }[keyof A & keyof B]
>;
export type PieceDenominator = Denominator<PieceEntity, UserPiece>;

// Vary background styles based on piece. Otherwise pawns are too fat and kings/
// queens are too small.
export const getPieceStyles = (
  piece?: PieceDenominator,
  original: boolean = true,
): React.CSSProperties => {
  if (!piece?.key) {
    return {};
  }

  const baseStyles: React.CSSProperties = {
    WebkitBackgroundSize: 'cover',
    backgroundImage: `url(${getPieceHref(piece.key, piece.player)})`,
    backgroundOrigin: 'content-box',
    backgroundPosition: 'center bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: `${getPieceHeight(piece.key)}% ${getPieceHeight(piece.key)}%`,
    imageRendering: 'pixelated' as const,
  };

  // Unused now, but can sub in custom styles by piece subKey
  let subKeyStyles: React.CSSProperties = {};
  switch (piece.subKey ?? getSubKeyFromKey(piece.key)) {
    case PieceKey.Pawn:
      subKeyStyles = {};
      break;
    case PieceKey.Bishop:
    case PieceKey.Knight:
      subKeyStyles = {};
      break;
    case PieceKey.Rook:
      subKeyStyles = {};
      break;
    case PieceKey.King:
    case PieceKey.Queen:
      subKeyStyles = {};
      break;
    default:
      throw new Error(`No background styles found for piece: ${piece.key}`);
  }

  // Custom styles by piece key
  let keyStyles: React.CSSProperties = {};
  switch (piece.key) {
    case PieceKey.Ooze: {
      const ooze = piece as Ooze;
      const pieceKey = ooze.piece?.key ?? ooze.subKey; // Piece may be undefined in UserPiece during piece selection
      keyStyles = {
        backgroundImage: `url(${getPieceHref(pieceKey, piece.player)})`,
        backgroundSize: `${getPieceHeight(pieceKey)}% ${getPieceHeight(pieceKey)}%`,
        filter: 'hue-rotate(90deg) saturate(120)',
      };
      break;
    }
    case PieceKey.Pinata: {
      const pinata = piece as Pinata;
      const _pieceKey = pinata.piece?.key ?? pinata.key;
      const pieceKey =
        _pieceKey === PieceKey.Dummy ? PieceKey.Pinata : _pieceKey;
      keyStyles = {
        backgroundImage: `url(${getPieceHref(pieceKey, piece.player)})`,
        backgroundSize: `${getPieceHeight(pieceKey)}% ${getPieceHeight(pieceKey)}%`,
      };
      break;
    }
    case PieceKey.Promoted: {
      const promoted = piece as PromotedPiece;
      const pieceKey = original ? promoted.subKey : promoted.piece.key;
      keyStyles = {
        backgroundImage: `url(${getPieceHref(pieceKey, piece.player)})`,
        backgroundSize: `${getPieceHeight(pieceKey)}% ${getPieceHeight(pieceKey)}%`,
      };
      break;
    }
  }

  return { ...baseStyles, ...subKeyStyles, ...keyStyles };
};

export const getPieceDisplayName = (key: PieceKey) => {
  switch (key) {
    case PieceKey.Bishop:
      return 'Bishop';
    case PieceKey.Bouncer:
      return 'Bouncer';
    case PieceKey.Camel:
      return 'Camel';
    case PieceKey.Dummy:
      return 'Dummy';
    case PieceKey.Epee:
      return 'Épée Pawn';
    case PieceKey.GoldenPawn:
      return 'Golden Pawn';
    case PieceKey.IronPawn:
      return 'Iron Pawn';
    case PieceKey.King:
      return 'King';
    case PieceKey.Knight:
      return 'Knight';
    case PieceKey.Marauder:
      return 'Marauder';
    case PieceKey.Ooze:
      return 'Ooze';
    case PieceKey.Pawn:
      return 'Pawn';
    case PieceKey.PawnWithKnife:
      return 'Pawn (with a knife)';
    case PieceKey.Phase:
      return 'Phase Rook';
    case PieceKey.Pilgrim:
      return 'Pilgrim';
    case PieceKey.Pinata:
      return 'Pinata';
    case PieceKey.Queen:
      return 'Queen';
    case PieceKey.RocketMan:
      return 'Rocketman';
    case PieceKey.Rook:
      return 'Rook';
    case PieceKey.Sumo:
      return 'Sumo';
    default:
      throw new Error(`No display name found for piece: ${key}`);
  }
};

export const getPieceHeight = (key: PieceKey): number => {
  switch (key) {
    // Base pieces
    case PieceKey.Pawn:
      return 60;
    case PieceKey.Knight:
      return 75;
    case PieceKey.Bishop:
      return 80;
    case PieceKey.Rook:
      return 65;
    case PieceKey.King:
      return 90;
    case PieceKey.Queen:
      return 85;

    // Custom pieces
    case PieceKey.Epee:
      return 71.5;
    case PieceKey.Pinata:
      return 85;
    case PieceKey.Pilgrim:
      return 95;
    case PieceKey.GoldenPawn:
      return 70;

    // Default to base piece height
    default:
      return getPieceHeight(getSubKeyFromKey(key));
  }
};

export const getPieceHref = (key: PieceKey, player: Player) => {
  if (key === PieceKey.Ooze || key === PieceKey.Promoted) {
    return '';
  }

  const pieceTag = getPieceTag(key, player);
  switch (pieceTag) {
    case '0bishop':
      return wbishop;
    case '0bouncer':
      return wbouncer;
    case '0camel':
      return wcamel;
    case '0epee':
      return wepee;
    case '0golden':
      return wgolden;
    case '0iron':
      return wiron;
    case '0king':
      return wking;
    case '0knight':
      return wknight;
    case '0marauder':
      return wmarauder;
    case '0pawn':
      return wpawn;
    case '0pawnWithKnife':
      return wpawnWithKnife;
    case '0phase':
      return wphase;
    case '0pilgrim':
      return wpilgrim;
    case '0pinata':
      return wpinata;
    case '0queen':
      return wqueen;
    case '0rocketman':
      return wrocketman;
    case '0rook':
      return wrook;
    case '0sumo':
      return wsumo;
    case '1bouncer':
      return bbouncer;
    case '1bishop':
      return bbishop;
    case '1camel':
      return bcamel;
    case '1epee':
      return bepee;
    case '1golden':
      return bgolden;
    case '1iron':
      return biron;
    case '1king':
      return bking;
    case '1knight':
      return bknight;
    case '1marauder':
      return bmarauder;
    case '1pawn':
      return bpawn;
    case '1pawnWithKnife':
      return bpawnWithKnife;
    case '1phase':
      return bphase;
    case '1pilgrim':
      return bpilgrim;
    case '1pinata':
      return bpinata;
    case '1queen':
      return bqueen;
    case '1rocketman':
      return brocketman;
    case '1rook':
      return brook;
    case '1sumo':
      return bsumo;
    default:
      throw new Error(`No piece found for tag: ${pieceTag}`);
  }
};

export type AbilityInfo = {
  icon: string;
  description: string;
  cta: string;
};

export const getPieceAbilityInfo = (piece: Piece): AbilityInfo | undefined => {
  switch (piece.key) {
    case PieceKey.RocketMan:
      return {
        icon: '🚀',
        cta: 'Launch Rocket',
        description:
          'Use this effect to launch Rocketman to a random open tile.',
      };
  }
};
