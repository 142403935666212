import { uniqBy } from 'lodash';
import { Board } from '../../Board';
import { Point } from '../../Point';
import { Player } from '../../constants';
import { toArray } from '../array';
import { getPointsFromDirections } from './getPointsFromDirections';

export const getPointsFromDirectionsWithBouncing = (
  point: Point,
  deltas: Point | Point[],
  board: Board,
  pointPlayer?: Player,
): Point[] => {
  deltas = toArray(deltas);

  const points: Point[] = [];
  const player = pointPlayer ?? board.getPieceAtPoint(point)?.player;

  if (player === undefined) {
    throw new Error('Must provide player or point with a piece.');
  }

  for (const direction of deltas) {
    const currPoints = getPointsFromDirections(point, direction, board);
    if (currPoints.length === 0) {
      continue;
    }

    const lastPoint = currPoints[currPoints.length - 1];
    const piece = board.getPieceAtPoint(lastPoint);
    if (piece || getDistanceToBoardEdge(lastPoint, board) > 0) {
      points.push(...currPoints);

      // Can't bounce if there is a piece at the last point before a wall or
      // if one of our own pieces is blocking the way.
      continue;
    }

    const bouncePoints = getPointsFromDirections(
      currPoints[currPoints.length - 1],
      getBounceDirection(point, direction, board),
      board,
      Infinity,
      true,
      player,
    );
    points.push(...currPoints, ...bouncePoints);
  }

  return uniqBy(points, (p) => p.toString());
};

const getBounceDirection = (
  point: Point,
  delta: Point,
  board: Board,
): Point => {
  if (delta.equals(new Point(1, -1)) || delta.equals(new Point(-1, 1))) {
    return point.x + point.y <= board.width - 1
      ? new Point(1, 1)
      : new Point(-1, -1);
  } else if (delta.equals(new Point(1, 1)) || delta.equals(new Point(-1, -1))) {
    return point.x >= point.y ? new Point(-1, 1) : new Point(1, -1);
  }

  throw new Error(`Invalid bounce direction: ${delta.toString()}`);
};

export const getDistanceToBoardEdge = (point: Point, board: Board): number => {
  return Math.min(
    point.x,
    point.y,
    board.width - 1 - point.x,
    board.height - 1 - point.y,
  );
};
