import { Board } from '../Board';
import { Point } from '../Point';
import { Piece, PieceEntity, PieceKey } from './Piece';

export class Dummy extends Piece {
  key = PieceKey.Dummy;
  // TODO: Allow any subKey
  subKey = PieceKey.Knight;

  constructor(data: Partial<PieceEntity>, board: Board) {
    super(data, board);
  }

  override clone(board?: Board): Dummy {
    return new Dummy(this.toJSON(), board ?? this.board);
  }

  override canAttackPoint(point: Point): boolean {
    return false;
  }

  override getMovementPoints(): Point[] {
    return [];
  }
}
