import type {} from '@redux-devtools/extension'; // required for devtools typing
import {
  ClientEvent,
  ClientEventPayload,
  Game,
  GameEntity,
  Player,
  ServerEvent,
  getUUID,
} from 'pixie-dust';
import { Socket } from 'socket.io-client';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { readLocalStorage, writeLocalStorage } from '../utils/localStorage';
import { DefaultEventsMap, socket } from './socket';
import { UserPiece } from './substitution';
import { fetchJSON } from '../utils/fetch';
import { BACKEND_API_URL } from '../utils/constants';

interface AppState {
  // App
  game: GameEntity | null;
  historicGame: GameEntity | null;
  historyPosition: number | null;
  isAuthenticated?: boolean;
  player: Player | null;
  redirectLink?: string;
  userId: string;
  rectOverlay: { [x: string]: { [y: string]: React.ReactNode } };
  setGame: (game: GameEntity | null) => void;
  setIsAuthenticated: (arg: boolean) => void;
  setPlayer: (player: Player) => void;
  setRedirectLink: (link: string) => void;
  setRectOverlay: (x: number, y: number, el: React.ReactNode) => void;
  fetchGameHistory: (delta: number) => Promise<void>;

  // Piece selection
  isFetchingUserPieces: boolean;
  selectedPiece: UserPiece | null;
  userPieces: UserPiece[];
  setIsFetchingUserPieces: (loading: boolean) => void;
  setSelectedPiece: (selectedPiece: UserPiece | null) => void;
  setUserPieces: (pieces: UserPiece[]) => void;

  updateAbilityLoading: (loading: boolean) => void;
  abilityLoading?: boolean;

  // Socket
  isConnected?: boolean;
  emit: <E extends ClientEvent>(
    event: E,
    ...args: ClientEventPayload<E>
  ) => void;
  on: (
    event: ServerEvent,
    cb: (...args: any[]) => void,
  ) => Socket<DefaultEventsMap, DefaultEventsMap>;
  setIsConnected: (isConnected: boolean) => void;
}

const historyCache: Record<string, GameEntity> = {};

export const useStore = create<AppState>()(
  devtools(
    persist(
      (set, get) => ({
        // App
        game: null,
        historicGame: null,
        historyPosition: null,
        player: null,
        rectOverlay: {},
        userId: (() => {
          let id = readLocalStorage('demoUserId');
          if (!id) {
            id = getUUID();
            writeLocalStorage('demoUserId', id);
          }
          return id;
        })(),
        fetchGameHistory: async (delta: number) => {
          const state = get();
          if (!state?.game?.history?.length) return;
          let nextPosition;
          // If null then the only thing we should be able to do is
          // go back the the previous position
          if (state.historyPosition == null) {
            nextPosition = state.game.history.length - 2;
          } else {
            nextPosition = state.historyPosition + delta;
          }

          // Back to current move
          if (nextPosition === state.game.history.length - 1) {
            set({
              historicGame: null,
              historyPosition: null,
            });
            return;
          }

          if (historyCache[nextPosition]) {
            set({
              historicGame: historyCache[nextPosition.toString()],
              historyPosition: nextPosition,
            });
            return;
          }

          const res = await fetchJSON<{ game: GameEntity; position: number }>(
            `${BACKEND_API_URL}/history/${state.game.gameId}/${nextPosition}?type=position`,
          );
          historyCache[nextPosition] = res.game;
          set({ historicGame: res.game, historyPosition: nextPosition });
        },
        setGame: (game) => set({ game }),
        setIsAuthenticated: (isAuthenticated) => set({ isAuthenticated }),
        setPlayer: (player) => set({ player }),
        setRedirectLink: (link) => set({ redirectLink: link }),
        setRectOverlay: (x, y, el) => {
          const current = { ...get().rectOverlay };
          if (!el) {
            delete current[x]?.[y];
          } else {
            current[x] = current[x] || {};
            current[x][y] = el;
          }

          set({ rectOverlay: current });
        },

        // Piece selection
        isFetchingUserPieces: true,
        selectedPiece: null,
        userPieces: [],
        setIsFetchingUserPieces: (isFetchingUserPieces) =>
          set({ isFetchingUserPieces }),
        setSelectedPiece: (selectedPiece) => set({ selectedPiece }),
        setUserPieces: (userPieces) => set({ userPieces }),

        updateAbilityLoading(loading) {
          set({ abilityLoading: loading });
        },

        // Socket
        isConnected: false,
        emit: (event, ...args) => {
          console.info(`WS: emitted ${event}`, { args });
          socket.emit(event, ...args);
        },
        on: (event, cb) => {
          return socket.on(event, cb);
        },
        setIsConnected: (isConnected: boolean) => set({ isConnected }),
      }),
      {
        partialize: (state) => ({
          isAuthenticated: state.isAuthenticated,
        }),
        name: 'app-store',
      },
    ),
  ),
);

useStore.subscribe((state, prevState) => {
  // Log state without functions
  const changes = Object.entries(state).reduce((changes, [key, value]) => {
    if (JSON.stringify((prevState as any)[key]) !== JSON.stringify(value)) {
      (changes as any)[key] = value;
    }
    return changes;
  }, {});
  if (Object.keys(changes).length > 0) {
    console.log('App store updated:', changes);
  }
});

export * from './socket';
export * from './substitution';
