import { customAlphabet } from 'nanoid';

/**
 * Generate a random uuid. Because we are using base62, length is set to 22 in
 * order to match the output space of uuidv4.
 * - 62^22 = 2.7e+39
 * - 16^32 = 3.4e+38
 */
export const getUUID = customAlphabet(
  '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz',
  22,
);
