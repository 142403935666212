export const BOARD_SIZE = 8;

export enum GameStatus {
  Created,
  PieceSelection,
  InGame,
  Rematch,
  RematchAccepted,
  Done,
  Draw,
}

export type GameResult = {
  code: GameResultCode;
  winner?: Player;
};

export enum GameResultCode {
  InProgress,
  Checkmate,
  Stalemate,
  DrawByAgreement,
  Resigned,
  // TimedOut,
  // Disconnected,
  // Aborted,
  // InsufficientMaterial,
  // ThreefoldRepetition,
  // FiftyMoveRule,
  // DeadPosition,
}

export enum Player {
  White,
  Black,
}
