import { Piece } from '../pieces';
import { AbilityOrchestrator } from './AbilityOrchestrator';

export interface AbilityPiece extends Piece {
  abilities: AbilityOrchestrator;
}

export function isAbilityPiece(piece: Piece): piece is AbilityPiece {
  return (
    piece &&
    'abilities' in piece &&
    piece.abilities instanceof AbilityOrchestrator
  );
}
