import { useEffect } from 'react';
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';
import Root from './Root';
import AcceptInvite from './pages/Duel/AcceptInvite';
import CreateInvite from './pages/Duel/CreateInvite';
import Game from './pages/Game';
import Login from './pages/Login';
import { useStore } from './store';
import './styles/index.css';
import { PrivateRoute } from './utils/PrivateRoute';

// TODO: add 404 and error pages
const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    children: [
      {
        path: '',
        element: <Navigate to="/login" />,
      },
      {
        path: '/login',
        element: <Login />,
      },
      {
        path: '/invite',
        element: <PrivateRoute />,
        children: [
          {
            index: true,
            element: <CreateInvite />,
          },
          {
            path: ':id',
            element: <AcceptInvite />,
          },
        ],
      },
      {
        path: '/game',
        element: <PrivateRoute />,
        children: [
          {
            index: true,
            element: <Navigate to="/invite" />,
          },
          {
            path: ':id',
            element: <Game />,
          },
        ],
      },
      {
        path: '*',
        element: <Navigate to="/invite" />,
      },
    ],
  },
]);

const initialPageLink =
  !window.location.pathname.includes('/login') &&
  window.location.pathname !== '/'
    ? window.location.pathname
    : '';

export function Routes() {
  const { redirectLink, setRedirectLink, isAuthenticated } = useStore();

  useEffect(() => {
    if (!redirectLink && !isAuthenticated) {
      setRedirectLink(initialPageLink);
    }
  }, [isAuthenticated, redirectLink, setRedirectLink]);

  return <RouterProvider router={router} />;
}
