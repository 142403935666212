import classNames from 'classnames';

type ButtonProps = {
  onClick?: () => void;
  children: React.ReactNode;
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
  disabled?: boolean;
  className?: string;
  alt?: boolean;
};

export function Button({
  onClick,
  disabled,
  children,
  type,
  className,
  alt,
}: ButtonProps) {
  return (
    <button
      disabled={disabled}
      type={type}
      onClick={(e: React.MouseEvent) => {
        e.stopPropagation();
        onClick?.();
      }}
      className={classNames(
        {
          [className ?? '']: !!className,
          'bg-oldBlue': alt,
          'py-1': alt,
          'active:scale-[0.96]': !disabled,
        },
        'button pixel custom-button-boxshadow select-none rounded-none bg-black px-10 py-3 font-runescape-chat text-[2.4rem] text-white disabled:opacity-50',
      )}
    >
      {children}
    </button>
  );
}
