import { Board } from '../../Board';
import { Point } from '../../Point';
import { DirectionedPiece } from '../DirectionedPiece';
import { PieceKey, PieceEntity } from '../Piece';

export class Bishop extends DirectionedPiece {
  directions: Point[] = [
    new Point(1, 1),
    new Point(-1, 1),
    new Point(1, -1),
    new Point(-1, -1),
  ];
  key = PieceKey.Bishop;

  constructor(data: Partial<PieceEntity>, board: Board) {
    super(data, board);
  }

  override clone(board?: Board): Bishop {
    return new Bishop(this.toJSON(), board ?? this.board);
  }
}
