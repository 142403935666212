import { Board } from '../../Board';
import { Point } from '../../Point';
import { Piece, PieceKey } from '../Piece';
import { Pawn } from './Pawn';

export class GoldenPawn extends Pawn {
  key = PieceKey.GoldenPawn;

  override clone(board?: Board): GoldenPawn {
    return new GoldenPawn(this.toJSON(), board ?? this.board);
  }

  // Because player wins by promoting this piece, we don't need to get user
  // input for promotion.
  override emitRequiredUserInputsForMove() {
    return true;
  }

  getIsThreateningVictory() {
    const isAboutToPromote =
      this.point.y === this.board.getLastRank(this.player) - 1;
    const blocker = this.board.getPieceAtPoint(
      new Point(this.point.x, this.board.getLastRank(this.player)),
    );
    return isAboutToPromote && !blocker;
  }
}

export function isGoldenPawn(piece: Piece): piece is GoldenPawn {
  return piece.key === PieceKey.GoldenPawn;
}
