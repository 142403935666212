import { Point, PointEntity } from './Point';
import { Player } from './constants';
import { PieceKey } from './pieces';

export type SubstitutionEntity = {
  key: PieceKey;
  player: Player;
  to: PointEntity;
  tokenId?: string;
};

export type SubstitutionError =
  | {
      code:
        | SubstitutionErrorCode.EmptyPoint
        | SubstitutionErrorCode.InvalidPlayer;
    }
  | {
      code: SubstitutionErrorCode.InvalidPiece;
      newPieceKey: PieceKey;
      oldPieceKey: PieceKey;
    };

export enum SubstitutionErrorCode {
  EmptyPoint,
  InvalidPiece,
  InvalidPlayer,
}

// TODO: in the future, can we send user JWT and derive player from that?
export class Substitution {
  key: PieceKey; // Key of new piece
  player: Player;
  to: Point;
  tokenId?: string;

  static fromJSON(data: SubstitutionEntity) {
    return new Substitution(
      data.key,
      data.player,
      Point.fromJSON(data.to),
      data.tokenId,
    );
  }

  constructor(key: PieceKey, player: Player, to: Point, tokenId?: string) {
    this.key = key;
    this.player = player;
    this.to = to;
    this.tokenId = tokenId;
  }

  clone(): Substitution {
    return Substitution.fromJSON(this.toJSON());
  }

  toJSON() {
    const json: SubstitutionEntity = {
      key: this.key,
      player: this.player,
      to: this.to.toJSON(),
    };
    if (this.tokenId) {
      json.tokenId = this.tokenId;
    }

    return json;
  }
}
