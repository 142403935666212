import { BOARD_SIZE } from './constants';

export type PointEntity = {
  x: number;
  y: number;
};

export class Point {
  x: number;
  y: number;
  notation?: string;

  static fromJSON(point: PointEntity) {
    return new Point(point.x, point.y);
  }

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
    this.notation = this.toNotation(); // TODO: remove this, exists for debugging purposes only
  }

  abs() {
    return new Point(Math.abs(this.x), Math.abs(this.y));
  }

  add(point: Point) {
    return new Point(this.x + point.x, this.y + point.y);
  }

  clone() {
    return new Point(this.x, this.y);
  }

  equals(point: Point) {
    return this.x === point.x && this.y === point.y;
  }

  normalize() {
    return new Point(Math.sign(this.x), Math.sign(this.y));
  }

  scale(scalar: number) {
    return new Point(this.x * scalar, this.y * scalar);
  }

  subtract(point: Point) {
    return new Point(this.x - point.x, this.y - point.y);
  }

  toNotation() {
    // If the point is off the board, return a placeholder.
    if (
      this.x < 0 ||
      this.x > BOARD_SIZE - 1 ||
      this.y < 0 ||
      this.y > BOARD_SIZE - 1
    ) {
      return '??';
    }

    const letter = String.fromCharCode(97 + this.x);
    return `${letter}${this.y + 1}`;
  }

  toJSON(): PointEntity {
    return { x: this.x, y: this.y };
  }

  toString() {
    return JSON.stringify(this.toJSON());
  }
}
