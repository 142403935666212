import { Board } from '../../Board';
import { Move } from '../../Move';
import { Point } from '../../Point';
import { DirectionedPiece } from '../DirectionedPiece';
import { Piece, PieceEntityWithHasMoved, PieceKey } from '../Piece';

export class Rook extends DirectionedPiece {
  directions: Point[] = [
    new Point(1, 0),
    new Point(-1, 0),
    new Point(0, 1),
    new Point(0, -1),
  ];
  hasMoved: boolean = false;
  key = PieceKey.Rook;

  constructor(data: Partial<PieceEntityWithHasMoved>, board: Board) {
    super(data, board);
    this.hasMoved = data.hasMoved ?? false;
  }

  override clone(board?: Board): Rook {
    return new Rook(this.toJSON(), board ?? this.board);
  }

  override onMoveEnd(move: Move): void {
    this.hasMoved = true;
  }

  override toJSON(): PieceEntityWithHasMoved {
    return {
      ...super.toJSON(),
      hasMoved: this.hasMoved,
    };
  }
}

export function isRook(p: Piece): p is Rook {
  return p.subKey === PieceKey.Rook;
}
