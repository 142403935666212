import { Piece } from './pieces/Piece';
import { Point, PointEntity } from './Point';

export interface TileEntity {
  point: PointEntity;
  pieceId?: number;
}

export class Tile {
  point: Point;
  piece?: Piece;

  constructor(point: Point) {
    this.point = point;
  }

  clone(): Tile {
    const tile = new Tile(this.point.clone());
    tile.piece = this.piece?.clone();
    return tile;
  }

  toJSON(): TileEntity {
    const tile: TileEntity = {
      point: this.point.toJSON(),
    };
    if (this.piece) {
      tile.pieceId = this.piece.id;
    }

    return tile;
  }
}
