import {
  BOARD_SIZE,
  Board,
  ClientEvent,
  GameResultCode,
  GameStatus,
  Piece,
  Point,
  getEmptyBoard,
} from 'pixie-dust';
import { HistoryMove } from 'pixie-dust/src/HistoryMove';
import { useCallback, useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import { useNavigate, useParams } from 'react-router-dom';
import { useWindowSize } from 'usehooks-ts';
import { Board as BoardComponent } from '../components/Board';
import { Button } from '../components/Button';
import Modal from '../components/Modal';
import { PlayerContainer } from '../components/PlayerContainer';
import { useGame } from '../hooks/useGame';
import usePrevious from '../hooks/usePrevious';
import { getPieceDescription, useStore } from '../store';
import '../styles/game.css';
import { BoardId } from '../utils/constants';
import { getPieceDisplayName } from '../utils/pieces';
import { translatePointForPlayer } from '../utils/point';
import { PieceSubstitution } from './PieceSubstitution';

function Game() {
  const { id } = useParams();
  const navigate = useNavigate();
  if (id === undefined) {
    navigate('/invite');
  }

  const { game, historicGame } = useGame();
  const historicPosition = useStore((state) => state.historyPosition);
  const fetchGameHistory = useStore((state) => state.fetchGameHistory);
  const [lastMove, setLastMove] = useState<HistoryMove | null>(null);
  const [showResignConfirmation, updateShowResignConfirmation] =
    useState(false);
  const [showDrawConfirmation, updateShowDrawConfirmation] = useState(false);
  const [pieces, setPieces] = useState(
    game ? Object.values(game.board.pieces) : [],
  );
  const [historicPieces, setHistoricPieces] = useState<Piece[] | null>(null);
  const [hoveredPiece, setHoveredPiece] = useState<Piece | null>(null);

  const emit = useStore((store) => store.emit);
  const userId = useStore((store) => store.userId);
  const player = useStore((store) => store.player) ?? null;
  const { width, height } = useWindowSize();

  const inProgress = game?.result.code === GameResultCode.InProgress;
  const otherPlayer = player == 1 ? 0 : 1;

  // Update state when move is made
  useEffect(() => {
    if (!game || !inProgress) {
      return;
    }

    game.translatePoint = (point: Point) => {
      return player !== null ? translatePointForPlayer(point, player) : point;
    };
    setPieces(Object.values(game.board.pieces));
  }, [game, inProgress, player]);

  useEffect(() => {
    if (!historicGame) {
      setHistoricPieces(null);
      return;
    }
    setHistoricPieces(Object.values(historicGame.board.pieces));
  }, [historicGame]);

  useEffect(() => {
    if (!game || !game.history || game.history.length === 0) {
      return;
    }

    const lastMove =
      game.history[
        historicPosition != null
          ? historicPosition - 1
          : game.history.length - 1
      ]?.clone();
    if (!lastMove) {
      setLastMove(null);
      return;
    }
    lastMove.from = game.translate(lastMove.from);
    lastMove.to = game.translate(lastMove.to);
    setLastMove(lastMove);
  }, [game, historicPosition]);

  const offerDraw = useCallback(() => {
    if (!game?.id) {
      throw new Error('Game ID required');
    }
    updateShowDrawConfirmation(true);
  }, [game]);

  const onConfirmDraw = (accept: boolean) => {
    if (!game?.id) {
      throw new Error('Game ID required');
    }
    emit(ClientEvent.Draw, game?.id, userId, accept);
  };

  const offerRematch = useCallback(() => {
    if (!game?.id) {
      throw new Error('Game ID required');
    }
    emit(ClientEvent.Rematch, game?.id, userId);
  }, [emit, game, userId]);

  const onConfirmResign = useCallback(() => {
    emit(ClientEvent.Resign, game!.id, userId);
    updateShowResignConfirmation(false);
  }, [game, userId, emit]);

  const resign = useCallback(() => {
    if (player === null) {
      return;
    }
    updateShowResignConfirmation(true);
  }, [player]);

  const playerReceivedDrawOffer =
    otherPlayer != null &&
    game?.playerStatuses[otherPlayer] &&
    game?.status === GameStatus.Draw;

  const playerOfferedDraw =
    player != null &&
    game?.playerStatuses[player] &&
    game?.status === GameStatus.Draw;

  const playerAwaitingRematch =
    player != null && game?.playerStatuses[player] && game?.rematchGameId;
  const playerReceivedRematchRequest =
    player != null && game?.playerStatuses[otherPlayer] && game?.rematchGameId;

  const currentPlayerIsWinner =
    game?.result.winner != null && game.result.winner === player;

  useEffect(() => {
    if (
      game &&
      game.status === GameStatus.RematchAccepted &&
      game?.rematchGameId
    ) {
      navigate(`/invite/${game?.rematchGameId}`, { state: { listen: true } });
    }
  }, [game, navigate]);

  const prevStatus = usePrevious(game?.status);

  useEffect(() => {
    // Draw request rejected
    if (
      game?.status === GameStatus.InGame &&
      prevStatus === GameStatus.Draw &&
      showDrawConfirmation
    ) {
      updateShowDrawConfirmation(false);
    }

    // Handles page refresh
    if (
      game?.status === GameStatus.Draw &&
      playerOfferedDraw &&
      !showDrawConfirmation
    ) {
      updateShowDrawConfirmation(true);
    }
  }, [game?.status, showDrawConfirmation, playerOfferedDraw, prevStatus]);

  const buttons = (
    <>
      {!playerAwaitingRematch && (
        <Button onClick={inProgress ? resign : offerRematch}>
          {inProgress ? 'Resign' : 'Rematch'}
        </Button>
      )}
      {inProgress && <Button onClick={() => offerDraw()}>Draw</Button>}
    </>
  );

  return (
    <div className="flex w-full flex-col items-center justify-center">
      {game?.status === GameStatus.InGame ||
      game?.status === GameStatus.Draw ||
      game?.status === GameStatus.Done ? (
        <>
          <div className="relative flex max-w-[750px] flex-col justify-center">
            <PlayerContainer
              game={game}
              pieces={historicPieces ?? pieces}
              player={player !== null ? Board.getOtherPlayer(player) : null}
            />
            <BoardComponent
              disabled={!!historicGame}
              game={historicGame ?? game}
              id={BoardId.Game}
              lastMove={lastMove}
              onTileMouseEnter={(e, point, piece) =>
                setHoveredPiece(piece ?? null)
              }
              onTileMouseLeave={() => setHoveredPiece(null)}
              tileClassName={(point) => {
                return '';
              }}
              tiles={historicGame?.tiles ?? game.tiles}
            />
            <PlayerContainer
              game={game}
              pieces={historicPieces ?? pieces}
              player={player}
            />

            <div className="relative clear-both mt-10 w-full gap-10">
              <div className="float-left flex gap-10">
                {game?.status !== GameStatus.Done && buttons}
              </div>
              <div className="float-right flex gap-5 self-center">
                <Button
                  className="px-8 py-3 text-[1.8rem]"
                  disabled={
                    historicPosition === 0 || game?.history?.length === 0
                  }
                  onClick={() => {
                    fetchGameHistory(-1);
                  }}
                >
                  {'<'}
                </Button>
                <Button
                  className="px-8 py-3 text-[1.8rem]"
                  disabled={historicPosition == null}
                  onClick={() => {
                    fetchGameHistory(1);
                  }}
                >
                  {'>'}
                </Button>
              </div>
            </div>
          </div>

          <div className="mt-8 h-[50px] text-3xl text-gray-800">
            {hoveredPiece &&
              getPieceDescription(hoveredPiece).map((desc) => (
                <div key={desc.key} style={{ marginBottom: '10px' }}>
                  <p>{getPieceDisplayName(desc.key)}: </p>
                  {desc.description}
                </div>
              ))}
          </div>

          {(showDrawConfirmation || playerReceivedDrawOffer) && (
            <Modal isOpen>
              {playerOfferedDraw && (
                <p className="pixel m-auto mt-10 text-5xl">
                  Waiting for response
                </p>
              )}
              {!playerOfferedDraw && (
                <>
                  <p className="pixel m-auto mt-10 text-5xl">
                    {playerReceivedDrawOffer
                      ? 'You have been offered a draw. Accept?'
                      : 'Are you sure you want to offer a draw?'}
                  </p>
                  <div className="mt-5 flex gap-5">
                    <Button onClick={() => onConfirmDraw(true)}>Yes</Button>
                    <Button
                      onClick={() => {
                        // if rejecting offer then emit with accept false
                        if (playerReceivedDrawOffer) {
                          onConfirmDraw(false);
                        }
                        updateShowDrawConfirmation(false);
                      }}
                    >
                      No
                    </Button>
                  </div>
                </>
              )}
            </Modal>
          )}
          {showResignConfirmation && (
            <Modal isOpen>
              <p className="pixel m-auto mt-10 text-5xl">
                Are you sure you want to resign?
              </p>
              <div className="mt-5 flex gap-5">
                <Button onClick={onConfirmResign}>Yes</Button>
                <Button onClick={() => updateShowResignConfirmation(false)}>
                  No
                </Button>
              </div>
            </Modal>
          )}
          {game?.status === GameStatus.Done && (
            <>
              <Modal isOpen={true}>
                <div className="text-center">
                  {game.result.code === GameResultCode.DrawByAgreement && (
                    <>
                      <p className="pixel m-auto text-7xl">Draw</p>
                    </>
                  )}
                  {currentPlayerIsWinner && (
                    <>
                      <p className="pixel m-auto text-7xl">You won :)</p>
                    </>
                  )}
                  {game.result.winner != null && !currentPlayerIsWinner && (
                    <>
                      <p className="pixel m-auto text-7xl">You lost :(</p>
                    </>
                  )}
                  {!!playerAwaitingRematch && (
                    <p className="pixel m-auto mt-10 text-5xl">
                      Awaiting rematch...
                    </p>
                  )}
                  {!!playerReceivedRematchRequest && (
                    <p className="pixel m-auto mt-10 text-5xl">Rematch?</p>
                  )}
                  <div className="relative mt-10 flex flex-row justify-center gap-10">
                    {buttons}
                  </div>
                </div>
              </Modal>
              {currentPlayerIsWinner && (
                <Confetti
                  style={{ zIndex: 99999 }}
                  width={width}
                  height={height}
                  tweenDuration={4000}
                />
              )}
            </>
          )}
        </>
      ) : (
        <PieceSubstitution
          boardProps={{
            game,
            tiles: game?.tiles ?? getEmptyBoard(BOARD_SIZE, BOARD_SIZE),
          }}
        />
      )}
    </div>
  );
}

export default Game;
