import { Piece, PieceKey, Player } from 'pixie-dust';
import { getPieceDisplayName } from '../utils/pieces';

export type UserPiece = {
  contractAddress: string;
  description: string;
  image: string;
  key: PieceKey;
  player: Player;
  subKey: PieceKey;
  tokenId: string;
};

export const getUserPieceUniqueId = (piece?: UserPiece) =>
  piece ? `${piece.contractAddress}:${piece.tokenId}` : null;

export const TEST_USER_PIECES = [
  {
    key: PieceKey.Camel,
    subKey: PieceKey.Knight,
    tokenId: '01',
    contractAddress: '0x0',
    image: 'pixiechess.xyz/assets/assetId',
    description: 'Leaps 3 tiles forward and 1 to the side in any direction.',
  },
  {
    key: PieceKey.Epee,
    subKey: PieceKey.Pawn,
    tokenId: '02',
    contractAddress: '0x0',
    image: 'pixiechess.xyz/assets/assetId',
    description: 'Has global en passant range.',
  },
  {
    key: PieceKey.Ooze,
    subKey: PieceKey.Pawn,
    tokenId: '03',
    contractAddress: '0x0',
    image: 'pixiechess.xyz/assets/assetId',
    description:
      'Starts as a pawn, gains the abilities of whatever it last captured.',
  },
  {
    key: PieceKey.Marauder,
    subKey: PieceKey.Bishop,
    tokenId: '04',
    contractAddress: '0x0',
    image: 'pixiechess.xyz/assets/assetId',
    description:
      'Moves 1 square in any direction, gains +2 range every time it kills an enemy piece.',
  },
  {
    key: PieceKey.RocketMan,
    subKey: PieceKey.King,
    tokenId: '05',
    contractAddress: '0x0',
    image: 'pixiechess.xyz/assets/assetId',
    description: 'Once per game, activate to escape to a random tile. ',
  },
  {
    key: PieceKey.Bouncer,
    subKey: PieceKey.Bishop,
    tokenId: '06',
    contractAddress: '0x0',
    image: 'pixiechess.xyz/assets/assetId',
    description:
      'Moves like a bishop but can bounce off the board edges once per move.',
  },
  {
    key: PieceKey.Sumo,
    subKey: PieceKey.Rook,
    tokenId: '07',
    contractAddress: '0x0',
    image: 'pixiechess.xyz/assets/assetId',
    description:
      "Can't capture, can knock enemy pieces back 2 tiles by moving into them. The more pieces in a row, the less it knocks them all back.",
  },
  {
    key: PieceKey.Pinata,
    subKey: PieceKey.Knight,
    tokenId: '08',
    contractAddress: '0x0',
    image: 'pixiechess.xyz/assets/assetId',
    description:
      'At the start of the game, this piece turns into a random bishop, knight, or rook-based piece.',
  },
  // {
  //   key: PieceKey.GoldenPawn,
  //   subKey: PieceKey.Pawn,
  //   tokenId: '09',
  //   contractAddress: '0x0',
  //   image: 'pixiechess.xyz/assets/assetId',
  //   description: 'If this pawn is promoted, win the game.',
  // },
  {
    key: PieceKey.IronPawn,
    subKey: PieceKey.Pawn,
    tokenId: '10',
    contractAddress: '0x0',
    image: 'pixiechess.xyz/assets/assetId',
    description: "Can't be captured, can't capture, can't promote.",
  },
  {
    key: PieceKey.PawnWithKnife,
    subKey: PieceKey.Pawn,
    tokenId: '11',
    contractAddress: '0x0',
    image: 'pixiechess.xyz/assets/assetId',
    description: 'Has +1 diagonal attack range towards the center.',
  },
  {
    key: PieceKey.Pilgrim,
    subKey: PieceKey.Bishop,
    tokenId: '12',
    contractAddress: '0x0',
    image: 'pixiechess.xyz/assets/assetId',
    description:
      'After moving 50 tiles resurrect a random allied piece from their starting point.',
  },
  {
    key: PieceKey.Phase,
    subKey: PieceKey.Rook,
    tokenId: '13',
    contractAddress: '0x0',
    image: 'pixiechess.xyz/assets/assetId',
    description:
      "Can move through allied pieces but can't capture through them.",
  },
];

export function getPieceDescription(
  piece: Piece,
): { key: PieceKey; description: string }[] {
  const description = findDescription(piece.toJSON().key);
  if (!description) {
    return [];
  }

  const descriptions = [{ key: piece.toJSON().key, description }];
  if (piece.toJSON().key === PieceKey.Ooze) {
    descriptions[0].description += ` Currently mimicking ${getPieceDisplayName(piece.key)}.`;
    const mimicked = findDescription(piece.key);
    if (mimicked) {
      descriptions.push({
        key: piece.key,
        description: mimicked,
      });
    }
  }

  return descriptions;
}

function findDescription(key: PieceKey): string | null {
  return TEST_USER_PIECES.find((p) => p.key === key)?.description ?? null;
}
