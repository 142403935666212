import { Point, PointEntity } from './Point';
import { AbilityOptions, AbilityOptionsEntity } from './ability';
import { Player } from './constants';
import { Piece, PieceKey } from './pieces';
import { CombineByReplacing } from './utils/types';

export type BaseMoveOptions = {
  promoteTo?: PieceKey; // TODO: This should be in AbilityOptions once promotion becomes an ability
  originMove?: Move;
};
export type BaseMoveOptionsEntity = CombineByReplacing<
  BaseMoveOptions,
  {
    originMove?: MoveEntity;
  }
>;

type _MoveOptions<T> = {
  [K in keyof T]: { ability?: K } & T[K];
}[keyof T];
export type MoveOptions = _MoveOptions<AbilityOptions> &
  BaseMoveOptions & { simulated?: boolean };
export type MoveOptionsEntity = _MoveOptions<AbilityOptionsEntity> &
  BaseMoveOptionsEntity;

export type MoveEntity = {
  from: PointEntity;
  opts?: MoveOptionsEntity;
  pieceId: number;
  pieceKey: PieceKey;
  player: Player;
  to: PointEntity;
};

/**
 * A `Move` represents a piece moving from one point to another or staying in
 * place and using an ability, in which case `from` and `to` will be the same
 * and `opts.ability` will be set.
 */
export class Move {
  from: Point;
  opts: MoveOptions;
  pieceId: number;
  pieceKey: PieceKey;
  player: Player;
  to: Point;

  static fromJSON(data: MoveEntity) {
    return new Move(
      Point.fromJSON(data.from),
      Point.fromJSON(data.to),
      data.pieceId,
      data.pieceKey,
      data.player,
      fromMoveOptionsEntity(data.opts),
    );
  }

  static to(piece: Piece, to: Point | null, opts?: MoveOptions) {
    return new Move(
      piece.point,
      to ?? piece.point,
      piece.id,
      piece.key,
      piece.player,
      opts,
    );
  }

  constructor(
    from: Point,
    to: Point,
    pieceId: number,
    pieceKey: PieceKey,
    player: Player,
    opts?: MoveOptions,
  ) {
    this.from = from;
    this.to = to;
    this.pieceId = pieceId;
    this.pieceKey = pieceKey;
    this.player = player;
    this.opts = opts || {};
  }

  clone() {
    return Move.fromJSON(this.toJSON());
  }

  toJSON() {
    const json: MoveEntity = {
      from: this.from.toJSON(),
      to: this.to.toJSON(),
      pieceId: this.pieceId,
      pieceKey: this.pieceKey,
      player: this.player,
    };
    if (Object.keys(this.opts).length > 0) {
      // This works because all the values in `opts` are JSON-serializable by
      // default or define a custom `toJSON` method.
      json.opts = JSON.parse(JSON.stringify(this.opts));
    }

    return json;
  }

  translate(translatePoint?: (point: Point) => Point) {
    if (!translatePoint) {
      return this.clone();
    }

    return new Move(
      translatePoint(this.from),
      translatePoint(this.to),
      this.pieceId,
      this.pieceKey,
      this.player,
      this.opts,
    );
  }
}

export function fromMoveOptionsEntity(opts?: MoveOptionsEntity): MoveOptions {
  if (!opts) {
    return {};
  }

  return JSON.parse(JSON.stringify(opts), (key, value) => {
    switch (key) {
      case 'forcePoint': {
        return Point.fromJSON(value);
      }
      case 'originMove': {
        return Move.fromJSON(value);
      }
      default: {
        return value;
      }
    }
  }) as MoveOptions;
}

export function isMoveOptionsForAbility<T extends keyof AbilityOptions>(
  opts: MoveOptions,
  ability: T,
): opts is MoveOptions & { ability: T } {
  return opts?.ability === ability;
}

export function isSimulatedMove(move: Move): boolean {
  return move.opts.simulated ?? false;
}
