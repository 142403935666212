import classNames from 'classnames';
import {
  AbilityName,
  ClientEvent,
  Events,
  Game,
  Move,
  MoveOptions,
  Piece,
  PieceKey,
  Point,
  Tile as TileObj,
} from 'pixie-dust';
import { HistoryMove } from 'pixie-dust/src/HistoryMove';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useStore } from '../../store';
import { Color } from '../../utils/constants';
import { translatePointForPlayer } from '../../utils/point';
import { AbilityTrigger } from '../AbilityTrigger';
import Modal from '../Modal';
import { PromotionSelection } from '../PromotionSelection';
import { BoardSvg, BoardSvgProps } from './BoardSvg';
import { CoordinateLayer } from './CoordinateLayer';
import { Tile } from './Tile';
import { isLightTile } from './utils';

export interface BoardProps {
  className?: string;
  lastMove?: HistoryMove | null;
  responsive?: boolean;
  disabled?: boolean;
  game: Game | null;
  id?: string;
  onTileMouseClick?: (e: React.MouseEvent, point: Point, piece?: Piece) => void;
  onTileMouseDown?: (e: React.MouseEvent, point: Point, piece?: Piece) => void;
  onTileMouseEnter?: (e: React.MouseEvent, point: Point, piece?: Piece) => void;
  onTileMouseLeave?: (e: React.MouseEvent, point: Point, piece?: Piece) => void;
  tileClassName?: string | ((point: Point, piece?: Piece) => string);
  tiles: TileObj[][];
  tileStyles?: React.CSSProperties;
}

function BoardUnmemo({
  className,
  game,
  id,
  disabled,
  onTileMouseClick,
  onTileMouseDown: onTileMouseDownFromProps,
  onTileMouseEnter,
  onTileMouseLeave,
  tileClassName,
  tiles,
  responsive = true,
  lastMove,
}: BoardProps) {
  const [pieceToPromote, setPieceToPromote] = useState<{
    move: Move;
    piece: Piece;
  }>();
  const [possibleMovePoints, setPossibleMovePoints] = useState<Point[]>([]);
  const [selectedPoint, setSelectedPoint] = useState<Point | null>(null);

  // TODO: Potentially move this out of board by moving onSelectPiece to store
  // so it can be accessed from anywhere
  const [abilityPiece, updateAbilityPiece] = useState<Piece | undefined>();
  const [showAbilityPopup, updateShowAbilityPopup] = useState(false);

  const emit = useStore((state) => state.emit);
  const player = useStore((state) => state.player);
  const historyPosition = useStore((state) => state.historyPosition);
  const userId = useStore((state) => state.userId);
  const updateAbilityLoading = useStore((state) => state.updateAbilityLoading);

  useEffect(() => {
    if (historyPosition != null) {
      setSelectedPoint(null);
      setPossibleMovePoints([]);
    }
  }, [historyPosition]);

  useEffect(() => {
    const onHandlePromotion = ({
      move,
      piece,
    }: {
      move: Move;
      piece: Piece;
    }) => {
      setPieceToPromote({ move, piece });
    };
    game?.addEventListener(Events.Promotion, onHandlePromotion);

    return () => {
      game?.removeEventListener(Events.Promotion, onHandlePromotion);
    };
  }, [game]);

  const onCanUseAbility = (piece: Piece | null) => {
    if (piece) {
      updateAbilityPiece(piece);
    } else {
      updateAbilityPiece(void 0);
    }
  };

  const onTileMouseDown = useCallback(
    (
      e: React.MouseEvent | null,
      to: Point,
      piece?: Piece,
      opts?: MoveOptions,
      force?: boolean,
    ) => {
      if (e && e.button !== 0) {
        return;
      }

      if (selectedPoint?.equals(to) && abilityPiece) {
        updateShowAbilityPopup(!showAbilityPopup);
        // clicked again show ability popup
      }
      if (!game || game.getTurn() !== player) {
        setSelectedPoint(null);
        setPossibleMovePoints([]);
        return;
      }

      if (piece && game.canUseAbility(piece, null)) {
        // Show ability UI if applicable
        onCanUseAbility(piece);
      } else {
        onCanUseAbility(null);
      }

      const hasPossibleMove = possibleMovePoints.some((p) => p.equals(to));

      if (selectedPoint && (hasPossibleMove || (force && !hasPossibleMove))) {
        const move = game.move(selectedPoint, to, opts);
        if (!move) return;
        emit(ClientEvent.Move, game.id, userId, move.toJSON());
        // Reset selection state
        setPossibleMovePoints([]);
        setSelectedPoint(null);
        return;
      }

      if (game.getPieceAtPoint(to)?.player === player) {
        setSelectedPoint(to);
        setPossibleMovePoints(game.getPossibleMoves(to));
      } else {
        setSelectedPoint(null);
        setPossibleMovePoints([]);
      }
    },
    [
      game,
      emit,
      player,
      possibleMovePoints,
      selectedPoint,
      userId,
      abilityPiece,
      showAbilityPopup,
    ],
  );

  const onPromotionSelection = useCallback(
    (key: PieceKey) => {
      if (pieceToPromote) {
        onTileMouseDown(
          null,
          translatePointForPlayer(pieceToPromote?.move?.to, player!),
          pieceToPromote.piece,
          {
            ability: AbilityName.Pawn_Promotion,
            promoteTo: key,
          },
        );
        setPieceToPromote(void 0);
      }
    },
    [onTileMouseDown, pieceToPromote, player],
  );

  const [size, updateSize] = useState(750);
  const sizePx = responsive ? `${size}px` : void 0;

  useEffect(() => {
    if (responsive) {
      const resizeHandler = () => {
        const wBuffer = 100;
        const hBuffer = 300;
        let newSize = Math.min(
          window.innerWidth - wBuffer,
          Math.max(window.innerHeight, 750) - hBuffer,
          750,
        );
        newSize = Math.max(newSize, 400); // Min width 400px

        // Update to resize only every 8 pixels and ensure it's an even number
        newSize = Math.floor(newSize / 8) * 8;
        if (newSize % 2 !== 0) {
          newSize -= 8;
        }

        updateSize(newSize);
      };
      window.addEventListener('resize', resizeHandler);
      resizeHandler();

      return () => {
        window.removeEventListener('resize', resizeHandler);
      };
    }
  }, [responsive]);

  const pointProps = useMemo(() => {
    const pointProps: BoardSvgProps['pointProps'] = {};
    if (selectedPoint) {
      pointProps[selectedPoint.toString()] = {
        fill: Color.SelectedTile,
      };
    }

    if (lastMove?.from) {
      pointProps[lastMove.from.toString()] = {
        fill: isLightTile(lastMove.from)
          ? Color.LastMoveTileLight
          : Color.LastMoveTileDark,
      };
    }

    if (lastMove?.to) {
      pointProps[lastMove.to.toString()] = {
        fill: isLightTile(lastMove.to)
          ? Color.LastMoveTileLight
          : Color.LastMoveTileDark,
      };
    }

    return pointProps;
  }, [selectedPoint, lastMove]);

  return (
    <>
      <Modal isOpen={!!pieceToPromote}>
        <PromotionSelection
          onCancel={() => {
            setSelectedPoint(null);
            setPossibleMovePoints([]);
            setPieceToPromote(void 0);
          }}
          onSelect={onPromotionSelection}
        />
      </Modal>
      {game !== null && (
        <div
          className={classNames(
            'relative border-none bg-cover bg-center bg-no-repeat outline-none',
            {
              [className!]: className,
              'pointer-events-none': disabled,
            },
          )}
          style={{
            width: sizePx,
            height: sizePx,
          }}
          id={id}
        >
          <BoardSvg pointProps={pointProps} />
          {tiles.map((tileRow) =>
            tileRow.map(({ point, piece }) => {
              const isPossible = possibleMovePoints.some((p) =>
                p.equals(point),
              );
              const isSelected =
                (selectedPoint?.equals(point) ?? false) ||
                !!(tileClassName as any)(point); // TODO: Fix this;

              const tile = (
                <Tile
                  className={tileClassName}
                  isPossible={isPossible}
                  key={point.toString()}
                  piece={piece}
                  point={point}
                  onMouseClick={disabled ? void 0 : onTileMouseClick}
                  onMouseDown={
                    disabled
                      ? void 0
                      : onTileMouseDownFromProps ?? onTileMouseDown
                  }
                  onMouseEnter={disabled ? void 0 : onTileMouseEnter}
                  onMouseLeave={disabled ? void 0 : onTileMouseLeave}
                />
              );

              let output = tile;
              if (
                showAbilityPopup &&
                isSelected &&
                abilityPiece &&
                !disabled &&
                piece &&
                game.canUseAbility(piece, null)
              ) {
                output = (
                  <AbilityTrigger
                    onTrigger={(move: Move) => {
                      updateAbilityLoading(true);
                      emit(ClientEvent.Move, game.id, userId, move.toJSON());
                      // Reset selection state
                      setPossibleMovePoints([]);
                      setSelectedPoint(null);
                    }}
                    piece={abilityPiece}
                    game={game}
                  >
                    {tile}
                  </AbilityTrigger>
                );
              }

              return output;
            }),
          )}
          <CoordinateLayer player={player} />
        </div>
      )}
    </>
  );
}

export const Board = React.memo(BoardUnmemo);
