import { Board, BoardEntity } from './Board';
import { HistoryMove, HistoryMoveEntity } from './HistoryMove';
import { Move, MoveOptions } from './Move';
import { Point } from './Point';
import { Substitution } from './Substitution';
import { Tile } from './Tile';
import { AbilityName, isAbilityPiece } from './ability';
import { GameResult, GameResultCode, GameStatus, Player } from './constants';
import { Piece } from './pieces';
import { getUUID } from './utils';

// TODO: Timer
// TODO: Move history
// TODO: Draw by repetition

export interface GameEntity {
  board: BoardEntity;
  gameId: string;
  history: {
    board?: BoardEntity;
    hash?: string;
    prevHash?: string;
    move?: HistoryMoveEntity;
  }[];
  playerIds: { [Player.White]: string; [Player.Black]: string };
  playerStatuses: { [Player.White]: boolean; [Player.Black]: boolean };
  result: GameResult;
  status: GameStatus;
  rematchGameId?: string;
}

export enum Events {
  Promotion = 'promotion',
  Checkmate = 'checkmate',
}

export type EventCallbackArgs = {
  [Events.Promotion]: { move: Move; piece: Piece };
  [Events.Checkmate]: { winner: Player };
};

export class Game {
  rematchGameId?: string;
  board: Board;
  history: HistoryMove[];
  id: string;
  playerIds: GameEntity['playerIds'];
  playerStatuses: GameEntity['playerStatuses'];
  result: GameResult;
  status: GameStatus;
  translatePoint: (point: Point) => Point;

  static fromJSON(
    data: GameEntity,
    translatePoint?: (point: Point) => Point,
  ): Game {
    return new Game(data.playerIds, {
      board: Board.fromJSON(data.board),
      history: data.history
        .filter((entry) => entry.move)
        .map((entry) => HistoryMove.fromJSON(entry.move!)),
      id: data.gameId,
      playerStatuses: data.playerStatuses,
      result: data.result,
      status: data.status,
      translatePoint,
      rematchGameId: data.rematchGameId,
    });
  }

  constructor(
    playerIds: GameEntity['playerIds'],
    opts?: {
      board?: Board;
      history?: HistoryMove[];
      id?: string;
      playerStatuses?: GameEntity['playerStatuses'];
      result?: GameResult;
      status?: GameStatus;
      translatePoint?: (point: Point) => Point;
      rematchGameId?: string;
    },
  ) {
    this.board = opts?.board ?? Board.new();
    this.history = opts?.history ?? [];
    this.id = opts?.id ?? getUUID();
    this.playerIds = playerIds;
    this.rematchGameId = opts?.rematchGameId;
    this.playerStatuses = opts?.playerStatuses ?? {
      [Player.White]: false,
      [Player.Black]: false,
    };
    this.result = opts?.result ?? { code: GameResultCode.InProgress };
    this.status = opts?.status ?? GameStatus.Created;
    this.translatePoint = opts?.translatePoint ?? ((point) => point);
  }

  addEventListener<K extends Events>(
    event: K,
    cb: (args: EventCallbackArgs[K]) => void,
  ): void {
    this.board.emitter.on(event as unknown as string, cb);
  }

  removeEventListener<K extends Events>(
    event: K,
    cb: (args: EventCallbackArgs[K]) => void,
  ): void {
    this.board.emitter.off(event as unknown as string, cb);
  }

  canUseAbility(piece: Piece, name: AbilityName | null, opts?: any) {
    const translatedPiece = piece.clone();
    translatedPiece.point = this.translatePoint(translatedPiece.point);
    return (
      isAbilityPiece(translatedPiece) &&
      translatedPiece.abilities.canUse(name, opts)
    );
  }

  clone(): Game {
    return Game.fromJSON(this.toJSON(), this.translatePoint);
  }

  draw() {
    if (this.status !== GameStatus.InGame) {
      throw new Error('Game is already over');
    }

    this.status = GameStatus.Rematch;
    this.result = { code: GameResultCode.DrawByAgreement };
  }

  getPieceAtPoint(point: Point) {
    return this.board.getPieceAtPoint(this.translatePoint(point));
  }

  getPlayer(userId: string): Player | null {
    const index = Object.values(this.playerIds).indexOf(userId);
    return index === -1 ? null : index;
  }

  getPossibleMoves(point: Point): Point[] {
    const piece = this.getPieceAtPoint(point);
    return piece?.getLegalPoints(true).map(this.translatePoint) ?? [];
  }

  getTurn(): Player {
    return this.history.length % 2 === 0 ? Player.White : Player.Black;
  }

  isSubstitutionLegal(substitution: Substitution) {
    const translatedSub = substitution.clone();
    translatedSub.to = this.translatePoint(translatedSub.to);
    return this.board.isSubstitutionLegal(translatedSub);
  }

  move(from: Point, to: Point, opts?: MoveOptions) {
    if (this.status !== GameStatus.InGame) {
      throw new Error('Illegal move: game is over');
    }

    from = this.translatePoint(from);
    to = this.translatePoint(to);
    const piece = this.board.getPieceAtPoint(from);
    if (!piece) {
      throw new Error(`No piece at ${from.toNotation()}`);
    }

    if (piece.player !== this.getTurn()) {
      throw new Error(
        `Illegal move: player ${piece.player} attempted to make a move out of turn`,
      );
    }

    // Get user inputs (ex. promotion selection) if needed
    const move = new Move(from, to, piece.id, piece.key, piece.player, opts);
    move.opts.simulated = true;
    const canMove = this.board.simulateEmitRequiredUserInputsForMove(move);
    if (!canMove) {
      return;
    }

    // Move piece
    move.opts.simulated = false;
    const effects = this.board.movePiece(move);
    const historyMove = HistoryMove.fromMove(move, effects);
    this.history.push(historyMove);

    // Determine if game is over
    const otherPlayer = Board.getOtherPlayer(piece.player);
    const result = this.board.isPlayerInCheckmateOrStalemate(otherPlayer);
    if (result !== GameResultCode.InProgress) {
      this.status = GameStatus.Done;
      this.result = { code: result };

      if (result === GameResultCode.Checkmate) {
        this.result.winner = piece.player;
        this.board.emitter.emit('checkmate', { winner: piece.player });
      }
    }

    return move;
  }

  resign(player: Player) {
    if (this.status !== GameStatus.InGame) {
      throw new Error('Game is already over');
    }

    this.status = GameStatus.Rematch;
    this.result = {
      code: GameResultCode.Resigned,
      winner: Board.getOtherPlayer(player),
    };
  }

  substitute(substitution: Substitution): Substitution {
    if (this.status !== GameStatus.PieceSelection) {
      throw new Error(
        `Illegal substitution: game ${this.id} is in phase ${this.status}`,
      );
    }

    const translatedSub = substitution.clone();
    translatedSub.to = this.translatePoint(translatedSub.to);
    this.board.substitutePiece(translatedSub);
    return translatedSub;
  }

  toJSON(): GameEntity {
    return {
      board: this.board.toJSON(),
      gameId: this.id,
      history: this.history.map((move) => ({ move: move.toJSON() })),
      playerIds: { ...this.playerIds },
      playerStatuses: { ...this.playerStatuses },
      result: { ...this.result },
      status: this.status,
    };
  }

  get tiles(): Tile[][] {
    if (!this.board || !this.board.tiles) {
      return [];
    }

    return this.board.tiles.map((row) =>
      row.map((tile) => {
        const tileCopy = tile.clone();
        tileCopy.point = this.translatePoint(tile.point);
        if (tileCopy.piece) {
          tileCopy.piece.point = this.translatePoint(tileCopy.piece.point);
        }

        return tileCopy;
      }),
    );
  }

  translate(point: Point): Point {
    return this.translatePoint(point);
  }

  // === DEBUG ===

  print() {
    const tiles = Array(8)
      .fill(null)
      .map(() => Array(8).fill('[ ]'));
    for (const piece of Object.values(this.board.pieces)) {
      const point = this.translatePoint(piece.point);
      const char = piece.key[0];
      const symbol = piece.player === Player.White ? char : char.toUpperCase();
      tiles[point.y][point.x] = `[${symbol}]`;
    }

    console.info(tiles.map((row) => row.join('')).join('\n'));
  }
}
