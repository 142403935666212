import { PieceKey } from '..';
import { Board } from '../../Board';
import { Move } from '../../Move';
import { Point } from '../../Point';
import { Pawn } from './Pawn';

export class IronPawn extends Pawn {
  canBeCaptured: boolean = false;
  key = PieceKey.IronPawn;

  override clone(board?: Board): IronPawn {
    return new IronPawn(this.toJSON(), board ?? this.board);
  }

  // Can't capture other pieces
  override getCapturePoints(): Point[] {
    return [];
  }

  // Can't promote
  override emitRequiredUserInputsForMove(move: Move): boolean {
    return true;
  }
}
