import { MoveEntity } from '../Move';
import { SubstitutionEntity } from '../Substitution';

// Prefix reserved event names with _: https://socket.io/docs/v3/emit-cheatsheet/
export enum ClientEvent {
  AcceptInvite = 'acceptInvite',
  Rematch = 'rematch',
  Resign = 'resign',
  Draw = 'draw',
  Connect = '_connect',
  Disconnect = '_disconnect',
  Move = 'move',
  Substitute = 'substitute',
}

type UserId = string;
type GameId = string;

// Define individual payload types for clarity
type SubstitutePayload = [string, string, SubstitutionEntity[]];
type MovePayload = [string, string, MoveEntity];
type ConnectPayload = [GameId, UserId];
type AcceptInvitePayload = [GameId, UserId, boolean | undefined];
type RematchPayload = [GameId, UserId];
type ResignPayload = [GameId, UserId];
type DrawPayload = [GameId, UserId, boolean | undefined];
type EmptyPayload = [];

export type ClientEventPayload<T extends ClientEvent> = {
  [ClientEvent.Substitute]: SubstitutePayload;
  [ClientEvent.Resign]: ResignPayload;
  [ClientEvent.Move]: MovePayload;
  [ClientEvent.Connect]: ConnectPayload;
  [ClientEvent.AcceptInvite]: AcceptInvitePayload;
  [ClientEvent.Rematch]: RematchPayload;
  [ClientEvent.Disconnect]: EmptyPayload;
  [ClientEvent.Draw]: DrawPayload;
}[T];

export enum ServerEvent {
  Error = 'error',
  Update = 'update',
}
