import classNames from 'classnames';
import React, { useCallback } from 'react';
import { TooltipWrapper } from '../../components/ToolTip/TooltipWrapper';
import { UserPiece, getUserPieceUniqueId, useStore } from '../../store';
import { getPieceDisplayName, getPieceStyles } from '../../utils/pieces';

type SelectionPieceProps = {
  className?: string;
  onMouseEnter?: (piece: UserPiece) => void;
  onMouseLeave?: () => void;
  piece?: UserPiece;
};

export function SelectedPiece({
  className,
  onMouseEnter,
  onMouseLeave,
  piece,
}: SelectionPieceProps) {
  const setSelectedPiece = useStore((state) => state.setSelectedPiece);
  const selectedPiece = useStore((state) => state.selectedPiece);
  const player = useStore((state) => state.player);

  const id = getUserPieceUniqueId(piece);
  const selectedPieceId = getUserPieceUniqueId(selectedPiece!);

  const onClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      if (piece && selectedPieceId !== id) {
        setSelectedPiece(piece);
      } else {
        setSelectedPiece(null);
      }
    },
    [id, piece, selectedPieceId, setSelectedPiece],
  );

  const styles = React.useMemo<React.CSSProperties>(
    () => getPieceStyles(piece),
    [piece],
  );

  if (!piece || player === null) return <></>;

  // TODO: This is mostly copied from PromotionSelection.tsx, might be nice to
  // refactor this into a shared component.
  return (
    <TooltipWrapper content={piece.description}>
      <div
        className={classNames(
          'flex flex-col items-center justify-center gap-4 border-2 p-5 shadow-sm hover:bg-gray-200',
          {
            'bg-gray-200': selectedPieceId == id,
            [className ?? '']: className,
          },
        )}
        onClick={onClick}
        onMouseEnter={(e: React.MouseEvent) => {
          e.stopPropagation();
          onMouseEnter?.(piece);
        }}
        onMouseLeave={(e: React.MouseEvent) => {
          e.stopPropagation();
          onMouseLeave?.();
        }}
      >
        <div
          className="flex h-[80px] w-[80px] bg-no-repeat" // TODO: scale 80x80 based on screen width
          style={styles}
        ></div>
        <span className="h-[40px] text-3xl text-pixie-gray-2">
          {getPieceDisplayName(piece.key)}
        </span>
      </div>
    </TooltipWrapper>
  );
}
