import { Point } from 'pixie-dust';
import React, { useRef } from 'react';
import { useStore } from '../../store/';
import { Color } from '../../utils/constants';
import styles from './styles.module.css';
import { isLightTile } from './utils';

const NUM_SQUARES = 64; // 8x8 chessboard
const TILE_SIZE = 40;

export type BoardSvgProps = {
  pointProps?: {
    [pointStr: string]: React.SVGProps<SVGRectElement>;
  };
};

// This uses a 320 x 320 board where each square is 40 x 40
function BoardSvgUnmemo({ pointProps }: BoardSvgProps) {
  // All rects saved as ref if we ever want to use this
  const rectsRef = useRef<any[]>([]);
  const rectOverlay = useStore((state) => state.rectOverlay);

  return (
    <div className={`absolute size-full ${styles['boardWrap']}`}>
      <svg
        width="320"
        height="320"
        viewBox="0 0 320 320"
        fill="none"
        shapeRendering="crispedges"
        xmlns="http://www.w3.org/2000/svg"
      >
        {Array.from({ length: NUM_SQUARES }).map((_, index) => {
          const point = new Point(index % 8, Math.floor(index / 8));
          const color = isLightTile(point) ? Color.TileLight : Color.TileDark;
          const props = pointProps?.[point.toString()];
          return (
            <rect
              ref={(el) => {
                rectsRef.current[index] = el;
              }}
              key={point.toString()}
              x={point.x * TILE_SIZE}
              y={point.y * TILE_SIZE}
              width={TILE_SIZE}
              height={TILE_SIZE}
              fill={color}
              {...props}
            />
          );
        })}
      </svg>
      {Object.entries(rectOverlay).map(([x, yMap]) => {
        return (
          <React.Fragment key={x}>
            {Object.entries(yMap).map(([y, el]) => {
              const idx = Number(y) * 8 + Number(x);
              const rect = rectsRef.current[idx];
              if (!rect) return null;
              const box = rect.getBoundingClientRect();
              return (
                <div
                  key={`${x}_${y}`}
                  style={{
                    position: 'fixed',
                    overflow: 'hidden',
                    pointerEvents: 'none',
                    width: `${box.width}px`,
                    height: `${box.height}px`,
                    left: `${box.left}px`,
                    top: `${box.top}px`,
                    zIndex: 10,
                  }}
                >
                  {el}
                </div>
              );
            })}
          </React.Fragment>
        );
      })}
    </div>
  );
}

export const BoardSvg = React.memo(BoardSvgUnmemo);
