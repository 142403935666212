import { PieceKey, getSubKeyFromKey } from 'pixie-dust';
import { useEffect, useState } from 'react';
import { useStore } from '../store';
import {
  PieceDenominator,
  getPieceDisplayName,
  getPieceStyles,
} from '../utils/pieces';
import { Button } from './Button';

type PromotionSelectionProps = {
  onSelect: (key: PieceKey) => void;
  onCancel: () => void;
};

export function PromotionSelection({
  onSelect,
  onCancel,
}: PromotionSelectionProps) {
  const player = useStore((state) => state.player);
  const [pieces, setPieces] = useState<PieceDenominator[]>([]);

  useEffect(() => {
    if (player === null) {
      onCancel();
      return;
    }

    setPieces(
      [PieceKey.Knight, PieceKey.Bishop, PieceKey.Rook, PieceKey.Queen].map(
        (key) => ({ key, player, subKey: getSubKeyFromKey(key) }),
      ),
    );
  }, [player, onCancel]);

  return (
    <div>
      <h2 className="mb-3 text-4xl">Promote Your Pawn</h2>
      <div className="flex gap-10">
        {pieces.map((piece) => (
          <div
            className={
              'flex w-full max-w-[200px] cursor-pointer flex-col items-center justify-center gap-4 border p-10 hover:bg-gray-100'
            }
            key={piece.key}
            onClick={() => {
              onSelect(piece.key);
            }}
          >
            <div
              className="flex h-[60px] w-1/2 bg-cover bg-center bg-no-repeat"
              style={getPieceStyles(piece)}
            ></div>
            <span>{getPieceDisplayName(piece.key)}</span>
          </div>
        ))}
      </div>
      <Button onClick={onCancel} className="mt-7">
        Cancel
      </Button>
    </div>
  );
}
