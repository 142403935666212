import { Board } from '../../Board';
import { Point } from '../../Point';
import { DirectionedPiece } from '../DirectionedPiece';
import { PieceKey, PieceEntity } from '../Piece';

export class Queen extends DirectionedPiece {
  directions: Point[] = [
    new Point(-1, -1),
    new Point(-1, 0),
    new Point(-1, 1),
    new Point(0, -1),
    new Point(0, 1),
    new Point(1, -1),
    new Point(1, 0),
    new Point(1, 1),
  ];
  key = PieceKey.Queen;

  constructor(data: Partial<PieceEntity>, board: Board) {
    super(data, board);
  }

  override clone(board?: Board): Queen {
    return new Queen(this.toJSON(), board ?? this.board);
  }
}
