import { Board } from '../Board';
import {
  Bishop,
  Bouncer,
  Camel,
  Dummy,
  Epee,
  GoldenPawn,
  IronPawn,
  King,
  Knight,
  Marauder,
  Ooze,
  Pawn,
  PawnWithKnife,
  Phase,
  Piece,
  PieceEntity,
  PieceKey,
  Pilgrim,
  Pinata,
  PromotedPiece,
  Queen,
  RocketMan,
  Rook,
  Sumo,
  type PieceEntityWithHasMoved,
} from '../pieces';

export const getSubKeyFromKey = (key: PieceKey) => {
  switch (key) {
    case PieceKey.Dummy:
      return PieceKey.Dummy;
    case PieceKey.Pawn:
    case PieceKey.Epee:
    case PieceKey.GoldenPawn:
    case PieceKey.IronPawn:
    case PieceKey.Ooze:
    case PieceKey.PawnWithKnife:
    case PieceKey.Promoted:
      return PieceKey.Pawn;
    case PieceKey.Knight:
    case PieceKey.Camel:
    case PieceKey.Pinata:
      return PieceKey.Knight;
    case PieceKey.Bishop:
    case PieceKey.Bouncer:
    case PieceKey.Marauder:
    case PieceKey.Pilgrim:
      return PieceKey.Bishop;
    case PieceKey.Rook:
    case PieceKey.Phase:
    case PieceKey.Sumo:
      return PieceKey.Rook;
    case PieceKey.Queen:
      return PieceKey.Queen;
    case PieceKey.King:
    case PieceKey.RocketMan:
      return PieceKey.King;
    default:
      throw new Error(`No base key found for piece: ${key}`);
  }
};

export function getPieceFromData(
  data: Partial<PieceEntityWithHasMoved> & { key: PieceKey },
  board: Board,
): Piece {
  switch (data.key) {
    case PieceKey.Bishop:
      return new Bishop(data, board);
    case PieceKey.Bouncer:
      return new Bouncer(data, board);
    case PieceKey.Camel:
      return new Camel(data, board);
    case PieceKey.Dummy:
      return new Dummy(data, board);
    case PieceKey.Epee:
      return new Epee(data, board);
    case PieceKey.GoldenPawn:
      return new GoldenPawn(data, board);
    case PieceKey.IronPawn:
      return new IronPawn(data, board);
    case PieceKey.King:
      return new King(data, board);
    case PieceKey.Knight:
      return new Knight(data, board);
    case PieceKey.Marauder:
      return new Marauder(data, board);
    case PieceKey.Ooze:
      return new Ooze(data, board);
    case PieceKey.Pawn:
      return new Pawn(data, board);
    case PieceKey.PawnWithKnife:
      return new PawnWithKnife(data, board);
    case PieceKey.Phase:
      return new Phase(data, board);
    case PieceKey.Pilgrim:
      return new Pilgrim(data, board);
    case PieceKey.Promoted:
      return new PromotedPiece(data, board);
    case PieceKey.Pinata:
      return new Pinata(data, board);
    case PieceKey.Queen:
      return new Queen(data, board);
    case PieceKey.RocketMan:
      return new RocketMan(data, board);
    case PieceKey.Rook:
      return new Rook(data, board);
    case PieceKey.Sumo:
      return new Sumo(data, board);
    default:
      throw new Error(`Unrecognized key: ${data.key}`);
  }
}

export const getPieceScore = (key: PieceKey): number => {
  switch (key) {
    case PieceKey.Dummy:
    case PieceKey.King:
      return 0;
    case PieceKey.Pawn:
      return 1;
    case PieceKey.Knight:
    case PieceKey.Bishop:
      return 3;
    case PieceKey.Rook:
      return 5;
    case PieceKey.Queen:
      return 9;
    default:
      return getPieceScore(getSubKeyFromKey(key));
  }
};

export const getPieceTag = (key: PieceKey, player: number) => {
  return `${player}${key}`;
};

export const isBasePiece = (piece: PieceEntity): boolean => {
  return (
    (piece.subKey === piece.key || piece.key === PieceKey.Promoted) &&
    [
      PieceKey.Bishop,
      PieceKey.King,
      PieceKey.Knight,
      PieceKey.Pawn,
      PieceKey.Queen,
      PieceKey.Rook,
    ].includes(piece.key)
  );
};

export const isPixiePiece = (piece: PieceEntity): boolean => {
  return !isBasePiece(piece);
};
