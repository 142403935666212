import { Point } from '../Point';
import { Tile } from '../Tile';
import { PieceEntity, PieceKey } from '../pieces';
import { Player } from './game';

export const getEmptyBoard = (width: number, height: number): Tile[][] => {
  return Array.from({ length: width }, (_, x) =>
    Array.from({ length: height }, (_, y) => new Tile(new Point(x, y))),
  );
};

export const getBasePieceById = (id: number) => {
  const piece = NEW_GAME_PIECES.find((p) => p.id === id);
  if (!piece) {
    throw new Error(`No piece found with id ${id}`);
  }

  return piece;
};

export const getStartingPointsBySubKey = (
  subKey: PieceKey,
  player: Player,
): Point[] => {
  return NEW_GAME_PIECES.filter(
    (p) => p.key === subKey && p.player === player,
  ).map((p) => Point.fromJSON(p.point));
};

export const NEW_GAME_PIECES: PieceEntity[] = [
  {
    subKey: PieceKey.Rook,
    captured: false,
    id: 1,
    key: PieceKey.Rook,
    player: Player.White,
    point: { x: 0, y: 0 },
  },
  {
    subKey: PieceKey.Knight,
    captured: false,
    id: 2,
    key: PieceKey.Knight,
    player: Player.White,
    point: { x: 1, y: 0 },
  },
  {
    subKey: PieceKey.Bishop,
    captured: false,
    id: 3,
    key: PieceKey.Bishop,
    player: Player.White,
    point: { x: 2, y: 0 },
  },
  {
    subKey: PieceKey.Queen,
    captured: false,
    id: 4,
    key: PieceKey.Queen,
    player: Player.White,
    point: { x: 3, y: 0 },
  },
  {
    subKey: PieceKey.King,
    captured: false,
    id: 5,
    key: PieceKey.King,
    player: Player.White,
    point: { x: 4, y: 0 },
  },
  {
    subKey: PieceKey.Bishop,
    captured: false,
    id: 6,
    key: PieceKey.Bishop,
    player: Player.White,
    point: { x: 5, y: 0 },
  },
  {
    subKey: PieceKey.Knight,
    captured: false,
    id: 7,
    key: PieceKey.Knight,
    player: Player.White,
    point: { x: 6, y: 0 },
  },
  {
    subKey: PieceKey.Rook,
    captured: false,
    id: 8,
    key: PieceKey.Rook,
    player: Player.White,
    point: { x: 7, y: 0 },
  },
  {
    subKey: PieceKey.Pawn,
    captured: false,
    id: 9,
    key: PieceKey.Pawn,
    player: Player.White,
    point: { x: 0, y: 1 },
  },
  {
    subKey: PieceKey.Pawn,
    captured: false,
    id: 10,
    key: PieceKey.Pawn,
    player: Player.White,
    point: { x: 1, y: 1 },
  },
  {
    subKey: PieceKey.Pawn,
    captured: false,
    id: 11,
    key: PieceKey.Pawn,
    player: Player.White,
    point: { x: 2, y: 1 },
  },
  {
    subKey: PieceKey.Pawn,
    captured: false,
    id: 12,
    key: PieceKey.Pawn,
    player: Player.White,
    point: { x: 3, y: 1 },
  },
  {
    subKey: PieceKey.Pawn,
    captured: false,
    id: 13,
    key: PieceKey.Pawn,
    player: Player.White,
    point: { x: 4, y: 1 },
  },
  {
    subKey: PieceKey.Pawn,
    captured: false,
    id: 14,
    key: PieceKey.Pawn,
    player: Player.White,
    point: { x: 5, y: 1 },
  },
  {
    subKey: PieceKey.Pawn,
    captured: false,
    id: 15,
    key: PieceKey.Pawn,
    player: Player.White,
    point: { x: 6, y: 1 },
  },
  {
    subKey: PieceKey.Pawn,
    captured: false,
    id: 16,
    key: PieceKey.Pawn,
    player: Player.White,
    point: { x: 7, y: 1 },
  },
  {
    subKey: PieceKey.Rook,
    captured: false,
    id: 17,
    key: PieceKey.Rook,
    player: Player.Black,
    point: { x: 0, y: 7 },
  },
  {
    subKey: PieceKey.Knight,
    captured: false,
    id: 18,
    key: PieceKey.Knight,
    player: Player.Black,
    point: { x: 1, y: 7 },
  },
  {
    subKey: PieceKey.Bishop,
    captured: false,
    id: 19,
    key: PieceKey.Bishop,
    player: Player.Black,
    point: { x: 2, y: 7 },
  },
  {
    subKey: PieceKey.Queen,
    captured: false,
    id: 20,
    key: PieceKey.Queen,
    player: Player.Black,
    point: { x: 3, y: 7 },
  },
  {
    subKey: PieceKey.King,
    captured: false,
    id: 21,
    key: PieceKey.King,
    player: Player.Black,
    point: { x: 4, y: 7 },
  },
  {
    subKey: PieceKey.Bishop,
    captured: false,
    id: 22,
    key: PieceKey.Bishop,
    player: Player.Black,
    point: { x: 5, y: 7 },
  },
  {
    subKey: PieceKey.Knight,
    captured: false,
    id: 23,
    key: PieceKey.Knight,
    player: Player.Black,
    point: { x: 6, y: 7 },
  },
  {
    subKey: PieceKey.Rook,
    captured: false,
    id: 24,
    key: PieceKey.Rook,
    player: Player.Black,
    point: { x: 7, y: 7 },
  },
  {
    subKey: PieceKey.Pawn,
    captured: false,
    id: 25,
    key: PieceKey.Pawn,
    player: Player.Black,
    point: { x: 0, y: 6 },
  },
  {
    subKey: PieceKey.Pawn,
    captured: false,
    id: 26,
    key: PieceKey.Pawn,
    player: Player.Black,
    point: { x: 1, y: 6 },
  },
  {
    subKey: PieceKey.Pawn,
    captured: false,
    id: 27,
    key: PieceKey.Pawn,
    player: Player.Black,
    point: { x: 2, y: 6 },
  },
  {
    subKey: PieceKey.Pawn,
    captured: false,
    id: 28,
    key: PieceKey.Pawn,
    player: Player.Black,
    point: { x: 3, y: 6 },
  },
  {
    subKey: PieceKey.Pawn,
    captured: false,
    id: 29,
    key: PieceKey.Pawn,
    player: Player.Black,
    point: { x: 4, y: 6 },
  },
  {
    subKey: PieceKey.Pawn,
    captured: false,
    id: 30,
    key: PieceKey.Pawn,
    player: Player.Black,
    point: { x: 5, y: 6 },
  },
  {
    subKey: PieceKey.Pawn,
    captured: false,
    id: 31,
    key: PieceKey.Pawn,
    player: Player.Black,
    point: { x: 6, y: 6 },
  },
  {
    subKey: PieceKey.Pawn,
    captured: false,
    id: 32,
    key: PieceKey.Pawn,
    player: Player.Black,
    point: { x: 7, y: 6 },
  },
];
