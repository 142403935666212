export const logger = (() => {
  let consoleLogFn: Console['log'] | null = null;
  let consoleTraceFn: Console['trace'] | null = null;
  return {
    enable: () => {
      if (consoleLogFn !== null) {
        console.log = consoleLogFn;
      }

      if (consoleTraceFn !== null) {
        console.trace = consoleTraceFn;
      }
    },
    disable: () => {
      consoleLogFn = console.log;
      console.log = function () {};
      consoleTraceFn = console.trace;
      console.trace = function () {};
    },
  };
})();
