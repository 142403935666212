import { Board } from '../../Board';
import { Point } from '../../Point';
import { getPointsFromDirectionsWithPhasing } from '../../utils/movement/getPointsFromDirectionsWithPhasing';
import { PieceKey } from '../Piece';
import { Rook } from './Rook';

export class Phase extends Rook {
  key = PieceKey.Phase;

  override clone(board?: Board): Rook {
    return new Phase(this.toJSON(), board ?? this.board);
  }

  override getMovementPoints(): Point[] {
    return getPointsFromDirectionsWithPhasing(
      this.point,
      this.directions,
      this.board,
      {
        includeFirstCapture: (didPhase) => !didPhase,
        phaseThroughOpponentPieces: false,
        phaseThroughSelfPieces: true,
      },
    );
  }
}
