import { Board, Game, Piece, Player } from 'pixie-dust';
import { CapturedPieces } from './CapturedPieces';
import { TurnIndicator } from './TurnIndicator';

type PlayerContainerProps = {
  game: Game;
  pieces?: Piece[];
  player: Player | null;
};

export function PlayerContainer({
  game,
  pieces = [],
  player,
}: PlayerContainerProps) {
  if (player === null) {
    return null;
  }

  return (
    <div className="flex h-28 w-full flex-row py-5">
      <div className="flex w-full flex-row justify-between">
        <div className="flex flex-row items-center justify-center gap-2">
          <div
            className="mr-2 flex size-16 items-center justify-center rounded-full text-center text-3xl"
            style={{
              backgroundColor: player === Player.White ? 'white' : 'black',
              color: player === Player.White ? 'black' : 'white',
            }}
          >
            :)
          </div>
          <div>
            <div className="text-2xl text-gray-800">
              Player {player === Player.White ? 'White' : 'Black'}
            </div>
            <CapturedPieces
              pieces={pieces}
              player={Board.getOtherPlayer(player)}
            />
          </div>
        </div>
        <TurnIndicator game={game} player={player} />
      </div>
    </div>
  );
}
