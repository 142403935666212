export const BACKEND_API_URL =
  import.meta.env.VITE_HTTP_URL || 'http://localhost:3000';

export const BOARD_SIZE = 8;

export const Color = {
  AppBackground: '#282c34',
  LastMoveTileDark: '#f5f4a0',
  LastMoveTileLight: '#fdfd96',
  SelectedTile: '#72d4fe',
  TileDark: '#bababa',
  TileLight: '#e0e0e0',
  Transparent: 'transparent',
} as const;

export const BoardId = {
  PieceSelection: 'piece-selection-board',
  Game: 'game-board',
} as const;

export const ClassName = {
  Hovered: 'hovered',
  PossibleCapture: 'possible-capture',
  PossibleMove: 'possible-move',
  PossibleSubstitution: 'possible-substitution',
  SubstitutedPiece: 'substituted-piece',
} as const;

export const Environment = {
  Development: 'development',
  Local: 'local',
  Production: 'production',
} as const;
