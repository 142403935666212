import { PieceKey } from '..';
import { Board } from '../../Board';
import { Point } from '../../Point';
import { Pawn, isPawn } from './Pawn';

export class Epee extends Pawn {
  key = PieceKey.Epee;

  override clone(board?: Board): Epee {
    return new Epee(this.toJSON(), board ?? this.board);
  }

  protected override getEnPassantPoint(): Point | null {
    const pawns = Object.values(this.board.pieces).filter((p) => {
      return (
        !!p &&
        isPawn(p) &&
        p.canBeCaptured &&
        p.canBeEnPassanted &&
        p.player !== this.player
      );
    }) as Pawn[];

    if (pawns.length === 0) {
      return null;
    } else if (pawns.length > 1) {
      throw new Error('More than one pawn can be en passanted');
    } else {
      const pawn = pawns[0];
      const direction = Board.getDirection(this.player);
      return new Point(pawn.point.x, pawn.point.y + direction);
    }
  }
}
