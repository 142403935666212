import { BACKEND_API_URL } from '../utils/constants';
import { fetchJSON } from '../utils/fetch';

type CreateInviteLinkResponse = {
  gameId: string;
};

export function createInviteLink(
  userId: string,
): Promise<CreateInviteLinkResponse> {
  const params = new URLSearchParams({ userId }).toString();
  return fetchJSON<CreateInviteLinkResponse>(
    `${BACKEND_API_URL}/invite?${params}`,
  );
}
