import { Board } from '../../Board';
import { Move } from '../../Move';
import { getStartingPointsBySubKey } from '../../constants';
import { PieceEntity, PieceKey } from '../Piece';
import { Bishop } from './Bishop';

export interface PilgrimEntity extends PieceEntity {
  numTilesTraveled: number;
}

const NUM_TILES_TO_RESURRECT = 30;

export class Pilgrim extends Bishop {
  key = PieceKey.Pilgrim;
  numTilesTraveled: number;

  constructor(data: Partial<PilgrimEntity>, board: Board) {
    super(data, board);
    this.numTilesTraveled = data.numTilesTraveled ?? 0;
  }

  override clone() {
    return new Pilgrim(this.toJSON(), this.board);
  }

  override onMoveEnd(move: Move): void {
    super.onMoveEnd(move);
    this.numTilesTraveled += Math.abs(move.from.x - move.to.x);

    // Resurrect a random piece if the pilgrim has traveled 50 tiles and reset
    // the count.
    if (this.numTilesTraveled >= NUM_TILES_TO_RESURRECT) {
      // Ensure only pieces that can be placed at a starting point are eligible
      const eligiblePieces = Object.values(this.board.pieces)
        .filter(
          (p) =>
            p.captured &&
            p.player === this.player &&
            p.toJSON().subKey !== PieceKey.Pawn,
        )
        .map((piece) => {
          const points = getStartingPointsBySubKey(
            piece.subKey,
            this.player,
          ).filter((point) => !this.board.getPieceAtPoint(point));
          return { piece, points };
        })
        .filter((data) => data.points.length > 0);

      // Place random piece at a random unoccupied starting point
      if (eligiblePieces.length > 0) {
        const { piece, points } =
          eligiblePieces[Math.floor(Math.random() * eligiblePieces.length)];
        const point = points[Math.floor(Math.random() * points.length)];
        this.board.placePieceAtPoint(piece, point);
        piece.captured = false;
      }

      this.numTilesTraveled %= NUM_TILES_TO_RESURRECT;
    }
  }

  override toJSON(): PilgrimEntity {
    return {
      ...super.toJSON(),
      numTilesTraveled: this.numTilesTraveled,
    };
  }
}
