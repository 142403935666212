import { Player, Point } from 'pixie-dust';
import { BOARD_SIZE } from './constants';

// These are reflections so calling this function twice should return the
// original point.
export function translatePointForPlayer(point: Point, player: Player): Point {
  return player === Player.White
    ? new Point(point.x, BOARD_SIZE - point.y - 1)
    : new Point(BOARD_SIZE - point.x - 1, point.y);
}
