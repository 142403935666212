import { toArray } from 'pixie-dust';

/**
 * Get value corresponding to given key from `localStorage`. If no such key
 * exists, return default value or null if a default value isn't given.
 * @param key
 * @param defaultVal
 */
export function readLocalStorage<K extends keyof LocalStorage>(
  key: K,
): LocalStorage[K] | null;
export function readLocalStorage<K extends keyof LocalStorage>(
  key: K,
  defaultVal?: LocalStorage[K],
): LocalStorage[K];
export function readLocalStorage<K extends keyof LocalStorage>(
  key: K,
  defaultVal?: LocalStorage[K],
) {
  try {
    const item = localStorage.getItem(key);
    if (item === null && defaultVal !== undefined) {
      writeLocalStorage(key, defaultVal);
    }

    return item ? JSON.parse(item) : defaultVal ?? null;
  } catch (e) {
    console.error(`Failed to get ${key} from local storage. Error: ${e}`);
    return defaultVal ?? null;
  }
}

/**
 * Write given key-value pair to `localStorage`. Returns whether or not write
 * was successful. Any thrown errors are suppressed and redirected to console.
 * @param key
 * @param val
 */
export function writeLocalStorage<K extends keyof LocalStorage>(
  key: K,
  val: LocalStorage[K],
): boolean {
  try {
    localStorage.setItem(key, JSON.stringify(val));
    return true;
  } catch (e) {
    console.error(`Failed to write ${key} to local storage. Error: ${e}`);
    return false;
  }
}

/**
 * Remove key-value pair with given key from `localStorage`.
 * @param key
 */
export function deleteLocalStorage<K extends keyof LocalStorage>(
  keys: K | K[],
): void {
  const keyList = toArray(keys);
  for (const key of keyList) localStorage.removeItem(key);
}

/**
 * `localStorage` key-to-type mapping.
 */
export interface LocalStorage {
  demoPassword: string;
  demoUserId: string;
}
