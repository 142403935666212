import { PieceEntityWithHasMoved, PieceKey } from '..';
import { Board } from '../../Board';
import { Effect } from '../../Effect';
import { Move, isMoveOptionsForAbility, isSimulatedMove } from '../../Move';
import { Point } from '../../Point';
import { AbilityEntity, AbilityName, AbilityOrchestrator } from '../../ability';
import { isServer } from '../../utils/env';
import { King } from './King';

export interface RocketManEntity extends PieceEntityWithHasMoved {
  abilities: AbilityEntity[];
}

// TODO: We should probably implement some sort of caching for eligible points
// using internal state that tracks turn #. This way we can avoid recalculating
// `board.doesMovePutSelfInCheck`, which is expensive, multiple times.
export class RocketMan extends King {
  abilities: AbilityOrchestrator;
  key = PieceKey.RocketMan;

  constructor(
    data: Partial<RocketManEntity> & { key: PieceKey },
    board: Board,
  ) {
    super(data, board);
    this.abilities = new AbilityOrchestrator(
      [
        {
          name: AbilityName.RocketMan_BlastOff,
          fn: this.blastOff.bind(this),
          canUseFn: this.canBlastOff.bind(this),
          state: { usedOnce: false },
        },
      ],
      data.abilities ?? [],
    );
  }

  blastOff(move: Move): Effect[] {
    if (!isMoveOptionsForAbility(move.opts, AbilityName.RocketMan_BlastOff)) {
      throw new Error('Invalid move options for ability RocketMan_BlastOff');
    }

    this.abilities.setState(AbilityName.RocketMan_BlastOff, 'usedOnce', true);
    if (!isServer()) {
      // Do nothing because we want randomness to come from server
      return [];
    }

    let randomPoint: Point;
    if (isSimulatedMove(move) && move.opts.forcePoint) {
      randomPoint = move.opts.forcePoint;
    } else {
      const eligiblePoints = this.eligiblePoints;
      randomPoint =
        eligiblePoints[Math.floor(Math.random() * eligiblePoints.length)];
    }

    this.board.forceMovePiece(
      Move.to(this, randomPoint, {
        originMove: move,
        simulated: isSimulatedMove(move),
      }),
    );
    return [
      {
        name: 'blastOff',
        point: randomPoint,
      },
    ] as Effect[];
  }

  canBlastOff(): boolean {
    const usedOnce = this.abilities.getState(
      AbilityName.RocketMan_BlastOff,
      'usedOnce',
    );
    return !usedOnce && this.eligiblePoints.length > 0;
  }

  override clone(board?: Board): RocketMan {
    return new RocketMan(this.toJSON(), board ?? this.board);
  }

  override getLegalPoints(debug?: boolean): Point[] {
    const points = super.getLegalPoints(debug);
    if (this.canBlastOff()) {
      points.push(this.point);
    }

    return points;
  }

  override onMoveEnd(move: Move): void {
    this.hasMoved = true;

    // Prevent castling if Rocketman is using ability
    if (move.opts?.ability !== AbilityName.RocketMan_BlastOff) {
      super.onMoveEnd(move);
    }
  }

  override toJSON(): RocketManEntity {
    return {
      ...super.toJSON(),
      abilities: this.abilities.toJSON(),
    };
  }

  private get eligiblePoints(): Point[] {
    return this.board.tiles
      .flat()
      .filter((tile) => !tile.piece && tile.point !== this.point)
      .filter(
        (tile) =>
          !this.board.doesMovePutSelfInCheck(
            Move.to(this, null, {
              ability: AbilityName.RocketMan_BlastOff,
              forcePoint: tile.point,
              simulated: true,
            }),
          ),
      )
      .map((tile) => tile.point);
  }
}
