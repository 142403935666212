import * as React from 'react';
import { useMergeRefs, FloatingPortal } from '@floating-ui/react';
import { TooltipOptions, useTooltip } from './useTooltip';
import { TooltipContext, useTooltipContext } from './useTooltipContext';

function TooltipUnmemo({
  children,
  ...options
}: { children: React.ReactNode } & TooltipOptions) {
  // This can accept any props as options, e.g. `placement`,
  // or other positioning options.
  const tooltip = useTooltip(options);
  return (
    <TooltipContext.Provider value={tooltip}>
      {children}
    </TooltipContext.Provider>
  );
}

export const Tooltip = React.memo(TooltipUnmemo);

export const TooltipTrigger = React.forwardRef<
  HTMLElement,
  React.HTMLProps<HTMLElement> & { asChild?: boolean }
>(({ children, asChild = false, ...props }, propRef) => {
  const context = useTooltipContext();
  const internalRef = React.useRef(null); // Create an internal ref
  const combinedRef = useMergeRefs([
    context.refs.setReference,
    propRef,
    internalRef,
  ]);

  // Clone the child element and pass the combined ref
  const cloneChildWithRef =
    React.isValidElement(children) && asChild
      ? React.cloneElement(children, {
          ref: combinedRef,
          ...props,
          ...children.props,
          'data-state': context.open ? 'open' : 'closed',
        })
      : null;

  // If `asChild` is true, render the cloned child. Otherwise, render a default button element.
  return asChild && cloneChildWithRef ? (
    cloneChildWithRef
  ) : (
    <button
      ref={combinedRef}
      // The user can style the trigger based on the state
      data-state={context.open ? 'open' : 'closed'}
      {...context.getReferenceProps(props)}
    >
      {children}
    </button>
  );
});

export const TooltipContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLProps<HTMLDivElement>
>(function TooltipContent({ style, ...props }, propRef) {
  const context = useTooltipContext();
  const ref = useMergeRefs([context.refs.setFloating, propRef]);

  if (!context.open) return null;

  return (
    <FloatingPortal>
      <div
        ref={ref}
        style={{
          ...context.floatingStyles,
          ...style,
        }}
        {...context.getFloatingProps(props)}
      />
    </FloatingPortal>
  );
});
