import { uniqBy } from 'lodash';
import { Board } from '../Board';
import { Move } from '../Move';
import { Point, PointEntity } from '../Point';
import { Player } from '../constants/game';
import { getPieceTag, getSubKeyFromKey } from '../utils';
import { isServer } from '../utils/env';

export enum PieceKey {
  Bishop = 'bishop',
  Camel = 'camel',
  Dummy = 'dummy',
  Epee = 'epee',
  GoldenPawn = 'golden',
  IronPawn = 'iron',
  King = 'king',
  Knight = 'knight',
  Marauder = 'marauder',
  Ooze = 'ooze',
  Pawn = 'pawn',
  PawnWithKnife = 'pawnWithKnife',
  Phase = 'phase',
  Pilgrim = 'pilgrim',
  Promoted = 'promoted',
  Pinata = 'pinata',
  Queen = 'queen',
  RocketMan = 'rocketman',
  Rook = 'rook',
  Bouncer = 'bouncer',
  Sumo = 'sumo',
}

export type PieceEntity = {
  subKey: PieceKey;
  captured: boolean;
  id: number;
  key: PieceKey;
  player: Player;
  point: PointEntity;
};

export type PieceEntityWithHasMoved = PieceEntity & {
  hasMoved: boolean;
};

export class Piece {
  subKey!: PieceKey;
  board: Board;
  canBeCaptured: boolean = true;
  captured: boolean = false;
  id: number;
  key!: PieceKey;
  player: Player;
  point: Point;

  constructor(data: Partial<PieceEntity>, board: Board) {
    if (data.player === undefined) {
      const key = data.key ?? data.subKey ?? 'piece';
      throw new Error(
        `Error creating ${key}: incomplete data ${JSON.stringify(data)}`,
      );
    }

    this.captured = data.captured ?? false;
    this.id = data.id ?? board.getNextId();
    this.point = Point.fromJSON(data.point ?? { x: 0, y: 0 }); // Use default value for pieces that will be placed later
    this.player = data.player;
    this.board = board;

    this.key = data.key ?? this.key;
    this.subKey = data.subKey ?? getSubKeyFromKey(this.key);
  }

  /** This is slow, so subclasses should likely override this. */
  canAttackPoint(point: Point): boolean {
    return this.getCapturePoints().some((p) => p.equals(point));
  }

  captureAtPoint(point: Point) {
    const piece = this.board.getPieceAtPoint(point);
    if (!piece) {
      throw new Error(`No piece to capture at ${point.toNotation()}`);
    }

    const move = new Move(
      this.point.clone(),
      point.clone(),
      this.id,
      this.key,
      this.player,
    );
    this.onCapture(move);
    this.board.removePiece(piece);
    piece.onCaptured(move);
    return piece;
  }

  clone(board?: Board): Piece {
    return new Piece(this.toJSON(), board ?? this.board);
  }

  emitRequiredUserInputsForMove(_move: Move) {
    return true;
  }

  getAllPoints(debug = false): Point[] {
    const allPoints = uniqBy(
      [...this.getMovementPoints(), ...this.getCapturePoints()],
      (p) => p.toString(),
    );
    debug &&
      console.info(
        `All points for ${this.key} at ${this.point.toNotation()}:`,
        allPoints.map((p) => p.toNotation()),
      );
    return allPoints;
  }

  getCapturePoints(): Point[] {
    // Technically, this is inefficient because we're adding a copy of every
    // movement point in `getAllPoints`, but it's useful for `canAttackPoint`.
    return this.getMovementPoints();
  }

  getLegalPoints(debug = false): Point[] {
    const legalPoints = this.getAllPoints(debug).filter((p) => {
      const piece = this.board.getPieceAtPoint(p);
      const canBeCaptured =
        piece && piece.player !== this.player && piece.canBeCaptured;
      return (
        (!piece || canBeCaptured) &&
        !this.board.doesMovePutSelfInCheck(
          Move.to(this, p, { simulated: true }),
        )
      );
    });
    debug &&
      console.info(
        `Legal points for ${this.key} at ${this.point.toNotation()}:`,
        legalPoints.map((p) => p.toNotation()),
      );
    return legalPoints;
  }

  getMovementPoints(): Point[] {
    return [];
  }

  getTag() {
    return getPieceTag(this.key, this.player);
  }

  onCapture(move: Move) {} // Do nothing

  onCaptured(move: Move) {
    this.captured = true;
  }

  onEveryOpponentTurnEnd(turn: Player) {} // Do nothing

  onEveryOpponentTurnStart(turn: Player) {} // Do nothing

  onEverySelfTurnEnd(turn: Player) {} // Do nothing

  onEverySelfTurnStart(turn: Player) {} // Do nothing

  onEveryTurnEnd(turn: Player) {} // Do nothing

  onEveryTurnStart(turn: Player) {} // Do nothing

  onGameStart() {
    if (!isServer()) {
      console.error('Piece.onGameStart() should only be called server-side.');
      return;
    }
  }

  onMoveEnd(move: Move) {} // Do nothing

  onMoveStart(move: Move) {
    if (this.id !== move.pieceId) {
      throw new Error(
        `Piece ID ${this.id} does not match move piece ID ${move.pieceId}`,
      );
    }

    // TODO
    const isAbility = move.to.equals(this.point);
    // isAbilityPiece(this) && move.opts?.ability && move.to.equals(this.point);

    // Capture
    const capturePiece = this.board.getPieceAtPoint(move.to);
    if (capturePiece && !isAbility) {
      if (!this.canAttackPoint(move.to)) {
        throw new Error(
          `Illegal move: ${this.key} on ${this.point.toNotation()} cannot capture ${capturePiece.key} on ${move.to.toNotation()}`,
        );
      }

      this.captureAtPoint(move.to);
    }
  }

  toJSON(): PieceEntity {
    return {
      subKey: this.subKey, // TODO: do we need this?
      captured: this.captured,
      id: this.id,
      key: this.key,
      player: this.player,
      point: this.point.toJSON(),
    };
  }
}
