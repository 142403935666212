import {
  SubstitutionError,
  SubstitutionErrorCode,
  getSubKeyFromKey,
} from 'pixie-dust';
import { getPieceDisplayName } from '../../utils/pieces';
import { capitalize } from '../../utils/string';

export const getSubstitutionErrorMessage = (error?: SubstitutionError) => {
  if (!error) {
    return '';
  }

  switch (error.code) {
    case SubstitutionErrorCode.EmptyPoint:
      return 'Must replace a piece.';
    case SubstitutionErrorCode.InvalidPiece: {
      const { newPieceKey, oldPieceKey } = error;
      const oldPieceDisplayName = capitalize(getPieceDisplayName(oldPieceKey));
      const newPieceDisplayName =
        getPieceDisplayName(newPieceKey).toLocaleLowerCase();
      const newBasePieceDisplayName = getPieceDisplayName(
        getSubKeyFromKey(newPieceKey),
      ).toLocaleLowerCase();
      return `${oldPieceDisplayName} cannot be substituted with ${newPieceDisplayName}. Try substituting out a ${newBasePieceDisplayName}-based piece instead.`;
    }
    case SubstitutionErrorCode.InvalidPlayer:
      return "Cannot replace opponent's piece.";
    default:
      throw new Error(`Unknown substitution error: ${error}`);
  }
};
