import classNames from 'classnames';
import { Game, Player } from 'pixie-dust';
import { useStore } from '../../store';

interface TurnIndicatorProps {
  game: Game;
  player: Player | null;
}

export function TurnIndicator({ game, player }: TurnIndicatorProps) {
  const userPlayer = useStore((store) => store.player) ?? null;

  if (player === null || userPlayer === null || game.getTurn() !== player) {
    return null;
  }

  return (
    <div className="flex h-full flex-row items-center p-5 text-2xl text-gray-800">
      <div
        className={classNames('mr-3 size-3 rounded-full', {
          'bg-green-600': game.getTurn() === userPlayer,
          'bg-red-600': game.getTurn() !== userPlayer,
        })}
      ></div>
      {game.getTurn() === userPlayer ? 'Your' : 'Their'} turn
    </div>
  );
}
