import EventEmitter from 'eventemitter3';
import { Effect } from './Effect';
import { Move } from './Move';
import { Point } from './Point';
import {
  Substitution,
  SubstitutionError,
  SubstitutionErrorCode,
} from './Substitution';
import { Tile } from './Tile';
import { isAbilityPiece } from './ability';
import {
  GameResultCode,
  NEW_GAME_PIECES,
  Player,
  getEmptyBoard,
} from './constants';
import { GoldenPawn, isGoldenPawn, type Piece } from './pieces';
import { PieceEntity, PieceKey } from './pieces/Piece';
import { getPieceFromData, getSubKeyFromKey, logger } from './utils';

export interface BoardEntity {
  pieces: PieceEntity[];
}

export class Board {
  height: number = 8;
  lastId: number = 1;
  pieces: { [id: number]: Piece } = {};
  tiles: Tile[][] = getEmptyBoard(8, 8);
  width: number = 8;
  emitter = new EventEmitter();

  static fromJSON(data: BoardEntity): Board {
    const board = new Board();
    board.lastId = Object.values(data.pieces).length + 1;
    board.pieces = data.pieces.reduce(
      (pieces, pieceData) => {
        pieces[pieceData.id] = getPieceFromData(pieceData, board);

        if (!pieceData.captured && pieceData.point) {
          board.placePieceAtPoint(
            pieces[pieceData.id],
            Point.fromJSON(pieceData.point),
          );
        }

        return pieces;
      },
      {} as { [id: number]: Piece },
    );
    return board;
  }

  static getDirection(player: Player): -1 | 1 {
    return player === Player.White ? 1 : -1;
  }

  static getOtherDirection(player: Player): -1 | 1 {
    return player === Player.White ? -1 : 1;
  }

  static getOtherPlayer(player: Player): Player {
    return player === Player.White ? Player.Black : Player.White;
  }

  static new(): Board {
    return Board.fromJSON({
      pieces: NEW_GAME_PIECES,
    });
  }

  private constructor() {}

  canPointBeAttackedByPlayer(point: Point, attacker: Player): boolean {
    const piece = this.getPieceAtPoint(point);
    return !!piece && piece.player !== attacker && piece.canBeCaptured;
  }

  clone(): Board {
    return Board.fromJSON(this.toJSON());
  }

  containsPoint(point: Point): boolean {
    return (
      point.x >= 0 &&
      point.x < this.width &&
      point.y >= 0 &&
      point.y < this.height
    );
  }

  doesMovePutSelfInCheck(move: Move): boolean {
    const board = this.simulateMove(move);
    return board.isPlayerInCheck(move.player);
  }

  forceGetPieceAtPoint(point: Point) {
    const piece = this.getPieceAtPoint(point);
    if (!piece) {
      throw new Error(`No piece at point ${point}`);
    }

    return piece;
  }

  // TODO: have handlers return effects and return them
  forceMovePiece(move: Move) {
    const piece = this.getPieceById(move.pieceId);
    if (!piece) {
      throw new Error(`No piece with ID ${move.pieceId}`);
    }

    const effects: Effect[] = [];
    piece.onMoveStart(move);

    // Handle move
    if (!move.from.equals(move.to)) {
      this.removePiece(piece);
      this.placePieceAtPoint(piece, move.to);
    }

    // Handle ability
    if (move.opts?.ability && isAbilityPiece(piece)) {
      effects.push(...piece.abilities.use(move.opts.ability, move));
    }

    piece.onMoveEnd(move);
    return effects;
  }

  getFirstRank(player: Player): number {
    return player === Player.White ? 0 : this.height - 1;
  }

  getLastRank(player: Player): number {
    return player === Player.White ? this.height - 1 : 0;
  }

  getNextId(): number {
    return this.lastId++;
  }

  getOtherPawnRank(player: Player): number {
    return player === Player.White ? this.height - 2 : 1;
  }

  getPawnRank(player: Player): number {
    return player === Player.White ? 1 : this.height - 2;
  }

  getPieceById(id: number): Piece | null {
    return this.pieces[id] || null;
  }

  getPieceAtPoint(point: Point): Piece | null {
    return this.getTile(point)?.piece || null;
  }

  getPiecesByPlayer(player: Player): Piece[] {
    return Object.values(this.pieces).filter(
      (piece) => piece.player === player && !piece.captured,
    );
  }

  getTile(point: Point): Tile | null {
    return (this.containsPoint(point) && this.tiles[point.x][point.y]) || null;
  }

  isPlayerInCheck(player: Player): boolean {
    const king = Object.values(this.pieces).find(
      (piece) => piece.subKey === PieceKey.King && piece.player === player,
    );

    if (!king) {
      throw new Error(`No king found for player ${player}`);
    }

    const goldenPawn = Object.values(this.pieces).filter(
      (piece) =>
        isGoldenPawn(piece) && piece.player === Board.getOtherPlayer(player),
    )[0] as GoldenPawn;
    if (goldenPawn) {
      console.log('Golden pawn:', goldenPawn.toJSON());
      console.log(goldenPawn.getIsThreateningVictory());
    }

    return (
      this.isPointAttackedByPlayer(
        king.point.clone(),
        Board.getOtherPlayer(player),
      ) ||
      (goldenPawn && goldenPawn.getIsThreateningVictory())
    );
  }

  isPlayerInCheckmateOrStalemate(player: Player): GameResultCode {
    const pieces = this.getPiecesByPlayer(player);
    const anyLegalMoves = pieces.some(
      (piece) => piece.getLegalPoints().length > 0,
    );
    if (anyLegalMoves) {
      return GameResultCode.InProgress;
    }

    return this.isPlayerInCheck(player)
      ? GameResultCode.Checkmate
      : GameResultCode.Stalemate;
  }

  isPointAttackedByPlayer(point: Point, player: Player): boolean {
    return this.getPiecesByPlayer(player).some((piece) =>
      piece.canAttackPoint(point),
    );
  }

  // TODO: should isLegal=true case return newPiece so this.substitute doesn't
  // need to recreate it?
  isSubstitutionLegal(substitution: Substitution): {
    isLegal: boolean;
    error?: SubstitutionError;
  } {
    const oldPieceData = this.getPieceAtPoint(substitution.to)?.toJSON();
    if (!oldPieceData) {
      return {
        isLegal: false,
        error: {
          code: SubstitutionErrorCode.EmptyPoint,
        },
      };
    }

    if (oldPieceData.player !== substitution.player) {
      return {
        isLegal: false,
        error: { code: SubstitutionErrorCode.InvalidPlayer },
      };
    }

    const newPieceData = getPieceFromData(
      {
        id: oldPieceData.id,
        key: substitution.key,
        player: substitution.player,
        point: oldPieceData.point,
        subKey: getSubKeyFromKey(substitution.key),
      },
      this,
    ).toJSON();
    if (oldPieceData.subKey !== newPieceData.subKey) {
      return {
        isLegal: false,
        error: {
          code: SubstitutionErrorCode.InvalidPiece,
          newPieceKey: newPieceData.key,
          oldPieceKey: oldPieceData.key,
        },
      };
    }

    return { isLegal: true };
  }

  placePieceAtPoint(piece: Piece, point: Point) {
    const tile = this.getTile(point);
    if (!tile) {
      throw new Error(`Invalid point ${point.toJSON()}`);
    }

    const existingPiece = this.getPieceAtPoint(point);
    if (existingPiece) {
      throw new Error(
        `Point ${point.toString()} ${point.notation} already occupied by ${existingPiece.key}.`,
      );
    }

    if (!piece.id) {
      piece.id = this.getNextId();
    }

    if (this.pieces[piece.id] !== piece) {
      this.pieces[piece.id] = piece;
    }

    tile.piece = piece;
    piece.point = point;
  }

  movePiece(move: Move) {
    const piece = this.getPieceById(move.pieceId);
    if (!piece) {
      throw new Error(`Illegal move: piece ${move.pieceId} doesn't exist.`);
    }

    console.info(
      `Moving ${piece.key} from ${move.from.toNotation()} to ${move.to.toNotation()}.`,
    );

    const isMoveLegal = piece
      .getLegalPoints()
      .some((point) => point.equals(move.to));
    if (!isMoveLegal) {
      throw new Error(
        `Illegal move: ${
          piece.key
        } from ${piece.point.toNotation()} to ${move.to.toNotation()}.`,
      );
    }

    const ability = move.opts?.ability;
    if (isAbilityPiece(piece) && ability && !piece.abilities.canUse(ability)) {
      throw new Error(
        `Illegal move: ${
          piece.key
        } on ${piece.point.toNotation()} cannot use ability ${ability}.`,
      );
    }

    // TODO: remove this when we handle abilities with a single click on frontend
    if (move.from.equals(move.to) && !ability) {
      throw new Error(
        `Illegal move: ${
          piece.key
        } on ${piece.point.toNotation()} cannot move to same point without using an ability.`,
      );
    }

    // Return effects emitted by move
    return [
      ...this.handleStartTurn(move),
      ...this.forceMovePiece(move),
      ...this.handleEndTurn(move),
    ];
  }

  removePiece(piece: Piece) {
    const tile = this.getTile(piece.point);
    if (!tile) {
      throw new Error(
        `Invalid point: ${piece.point.toJSON()} ${piece.point.toNotation()}`,
      );
    }

    tile.piece = undefined;
    return piece;
  }

  simulateEmitRequiredUserInputsForMove(move: Move): boolean {
    return this.simulate(move, (move, board) => {
      // Copy event listeners to cloned board
      board.emitter = this.emitter;

      const piece = board.getPieceById(move.pieceId);
      if (!piece) {
        throw new Error(`No piece with ID ${move.pieceId}.`);
      }

      piece.onMoveStart(move);
      return piece.emitRequiredUserInputsForMove(move);
    });
  }

  simulateMove(move: Move): Board {
    return this.simulate(move, (move, board) => {
      board.forceMovePiece(move);
      return board;
    });
  }

  substitutePiece(substitution: Substitution) {
    const { isLegal, error } = this.isSubstitutionLegal(substitution);
    if (!isLegal) {
      throw new Error(`Illegal substitution: ${error}`);
    }

    const oldPiece = this.getPieceAtPoint(substitution.to)!;
    const newPiece = getPieceFromData(
      {
        id: oldPiece.id,
        key: substitution.key,
        player: substitution.player,
        point: oldPiece.point.toJSON(),
        subKey: getSubKeyFromKey(substitution.key),
      },
      this,
    );
    this.removePiece(oldPiece);
    this.placePieceAtPoint(newPiece, substitution.to);
  }

  toJSON(): BoardEntity {
    const pieces = Object.values(this.pieces).map((piece) => piece.toJSON());
    return { pieces };
  }

  private simulate<T>(move: Move, fn: (move: Move, board: Board) => T): T {
    if (!move.opts.simulated) {
      throw new Error(`Move must be simulated: ${move.toJSON()}`);
    }

    // Silence logs from cloned board
    // logger.disable();
    const board = this.clone();
    board.handleStartTurn(move);
    const result = fn(move, board);
    board.handleEndTurn(move);
    logger.enable();
    return result;
  }

  private handleEndTurn(move: Move): Effect[] {
    // TODO: have handlers return effects and return them here
    const effects: Effect[] = [];
    for (const piece of Object.values(this.pieces)) {
      if (piece.captured) {
        continue;
      }

      if (move.player === piece.player) {
        piece.onEverySelfTurnEnd(move.player);
      } else {
        piece.onEveryOpponentTurnEnd(move.player);
      }

      piece.onEveryTurnEnd(move.player);
    }

    return effects;
  }

  private handleStartTurn(move: Move): Effect[] {
    const effects: Effect[] = [];
    for (const piece of Object.values(this.pieces)) {
      if (piece.captured) {
        continue;
      }

      if (move.player === piece.player) {
        piece.onEverySelfTurnStart(move.player);
      } else {
        piece.onEveryOpponentTurnStart(move.player);
      }

      piece.onEveryTurnStart(move.player);
    }

    return effects;
  }
}
