import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

interface ModalProps {
  isOpen: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
}

const Modal = ({ isOpen, onClose, children }: ModalProps) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div
      data-poll={`${isOpen}`}
      className="fixed inset-0 z-40 overflow-y-auto bg-black/40 p-2"
    >
      <div className="animate-slide-up relative bottom-[50%] top-[10%] mx-auto w-full max-w-[600px] rounded-lg border border-black bg-white p-10 text-3xl text-black shadow-2xl">
        {onClose && (
          <button
            onClick={onClose}
            className="absolute right-2 top-2 cursor-pointer border-none bg-transparent text-2xl text-gray-600"
          >
            &times;
          </button>
        )}
        {children}
      </div>
    </div>,
    document.body,
  );
};

export default Modal;
