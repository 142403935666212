import { Board } from '../../Board';
import { getSubKeyFromKey } from '../../utils';
import { PieceKey } from '../Piece';
import { PromotedPiece, PromotedPieceEntity } from '../pawns/PromotedPiece';

export class Pinata extends PromotedPiece {
  _key = PieceKey.Pinata;
  _subKey = PieceKey.Knight;

  constructor(
    data: Partial<PromotedPieceEntity> & { key: string },
    board: Board,
  ) {
    super({ ...data, subKey: PieceKey.Dummy }, board);
  }

  override clone(board?: Board): Pinata {
    return new Pinata(this.toJSON(), board ?? this.board);
  }

  override onGameStart(): void {
    super.onGameStart();
    const eligibleKeys = Object.values(PieceKey)
      .filter((key) => key !== PieceKey.Pinata)
      .filter((key) => {
        const subKey = getSubKeyFromKey(key);
        return (
          subKey === PieceKey.Knight ||
          subKey === PieceKey.Bishop ||
          subKey === PieceKey.Rook
        );
      });
    const key = eligibleKeys[Math.floor(Math.random() * eligibleKeys.length)];
    this.promoteTo({ key }, this.board);
  }
}
