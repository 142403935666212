import { ClientEvent, GameStatus } from 'pixie-dust';
import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { ErrorFallback } from './pages/Error';
import { useStore } from './store';

function Root() {
  const game = useStore((state) => state.game);
  const userId = useStore((state) => state.userId);
  const isAuthenticated = useStore((state) => state.isAuthenticated);
  const { id } = useParams(); // Invite or game ID
  const emit = useStore((state) => state.emit);
  const navigate = useNavigate();

  useEffect(() => {
    if (!game && id && isAuthenticated) {
      emit(ClientEvent.Connect, id, userId);
    }
  }, [game, emit, id, isAuthenticated, userId]);

  useEffect(() => {
    if (!game) {
      return;
    }

    if (
      game.status === GameStatus.PieceSelection ||
      game.status === GameStatus.InGame
    ) {
      navigate(`/game/${game.gameId}`);
    }
  }, [game, navigate]);

  return (
    <div className="flex size-full min-w-[485px] flex-col items-center justify-center p-20">
      <h1 className="text-stroke my-10 select-none font-runescape-large text-9xl text-black">
        Pixie Chess
      </h1>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Outlet />
      </ErrorBoundary>
    </div>
  );
}

export default Root;
