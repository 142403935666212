import { readLocalStorage } from './localStorage';

const HASHED_PASSWORD =
  'bb70ae66abe4b7e64ffa39c0003233db92c005940b324f38f019def2a1a5a5e4';

const PREV_PASSWORDS: string[] = [
  'af1511b8e3f460138059bbe966ab73b626a925a9f78efdf3c4f6b32b74c3d159',
];

export async function login(attempt?: string): Promise<boolean> {
  const password = attempt ?? readLocalStorage('demoPassword');
  if (!password) return false;

  const buffer = await crypto.subtle.digest(
    'sha-256',
    new TextEncoder().encode(password),
  );
  const hex = Array.from(new Uint8Array(buffer))
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');
  return hex === HASHED_PASSWORD;
}

export function isPreviousPassword(hash: string): boolean {
  return PREV_PASSWORDS.includes(hash);
}
