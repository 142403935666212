import { Button } from './Button';
import { Input } from './Input';

export function InviteLinkInput({
  inviteLink,
  waiting,
}: {
  inviteLink: string;
  waiting?: boolean;
}) {
  return (
    <div className="flex w-full max-w-[700px] flex-col items-center justify-center gap-10">
      {waiting && (
        <p className="text-5xl text-pixie-gray-2">
          Waiting for other player...
        </p>
      )}
      <Input value={inviteLink} inputDisabled={true} />
      <Button onClick={() => navigator.clipboard.writeText(inviteLink)}>
        Copy link
      </Button>
    </div>
  );
}
