import { useEffect, useRef } from 'react';

interface InputProps {
  inputContent?: string;
  inputDisabled?: boolean;
  inputPlaceholder?: string;
  isPassword?: boolean;
  value: string;
  onChange?: (val: string) => void;
}

export function Input({
  inputDisabled = false,
  inputPlaceholder,
  isPassword = false,
  value,
  onChange,
}: InputProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <>
      <div className="inset-shadow flex w-full border border-inputBorder bg-pixie-gray-3 text-white caret-white">
        <input
          autoComplete="off"
          autoCorrect="off"
          className="m-0 mx-4 my-2 w-full resize-none bg-transparent text-[2.4rem] text-pixie-gray-2 caret-white outline-none"
          maxLength={20}
          onChange={(e) => onChange?.(e.target.value)}
          placeholder={inputPlaceholder}
          disabled={inputDisabled}
          style={{ opacity: inputDisabled ? 0.5 : 1 }}
          ref={inputRef}
          type={isPassword ? 'password' : 'text'}
          value={value ?? ''}
        />
      </div>
    </>
  );
}
