import { Board } from '../../Board';
import { Point } from '../../Point';
import { Player } from '../../constants';
import { toArray } from '../array';

export function getPointsFromMoves(
  point: Point,
  deltas: Point | Point[],
  board: Board,
  pointPlayer?: Player,
): Point[] {
  deltas = toArray(deltas);

  const points = [];
  const player = pointPlayer ?? board.getPieceAtPoint(point)?.player;

  if (player === undefined) {
    throw new Error('Must provide player or point with a piece.');
  }

  for (const move of deltas) {
    const newPoint = point.add(move);
    if (!board.containsPoint(newPoint)) {
      continue;
    }

    const piece = board.getPieceAtPoint(newPoint);
    if (piece) {
      if (piece.player !== player) {
        points.push(newPoint);
      }

      continue;
    }

    points.push(newPoint);
  }

  return points;
}
