import { cloneDeep } from 'lodash';
import { Effect } from '../Effect';
import { Move } from '../Move';
import { AbilityName } from './AbilityName';
import { AbilityState } from './AbilityState';

interface _Ability<N extends AbilityName> {
  canUseFn: (...args: any[]) => boolean;
  fn: (move: Move) => Effect[];
  name: N;
  state: AbilityState[N];
}

interface _AbilityEntity<N extends AbilityName> {
  name: N;
  state: AbilityState[N];
}

export type Ability = _Ability<AbilityName>;
export type AbilityEntity = _AbilityEntity<AbilityName>;

export class AbilityOrchestrator {
  private _abilities: Ability[];

  constructor(abilities: Ability[], data: AbilityEntity[]) {
    this._abilities = abilities.map((ability) => {
      const state = data.find((ae) => ae.name === ability.name)?.state;
      if (state !== undefined) {
        Object.assign(ability.state, state);
      }

      return ability;
    });
  }

  /**
   * For now, allow `name` param to be null to automatically select the first
   * ability. TODO: Remove this when we have a piece with multiple abilities and
   * need to select which one to use.
   */
  canUse(name: AbilityName | null, ...args: any[]): boolean {
    return this.get(name).canUseFn(...args);
  }

  get<N extends AbilityName>(name: N | null): _Ability<N> {
    if (!name) {
      name = this.list()[0] as N;
    }

    const ability = this._abilities.find((ability) => ability.name === name);
    if (!ability) {
      throw new Error(`Piece has no ability ${name}.`);
    }

    return ability as _Ability<N>;
  }

  getState<N extends keyof AbilityState, S extends keyof AbilityState[N]>(
    name: N,
    key: S,
  ): AbilityState[N][S] {
    return this.get(name).state[key];
  }

  list(): AbilityName[] {
    return this._abilities.map((ability) => ability.name);
  }

  setState<N extends keyof AbilityState, S extends keyof AbilityState[N]>(
    name: N,
    key: S,
    value: AbilityState[N][S],
  ) {
    this.get(name).state[key] = value;
  }

  toJSON(): AbilityEntity[] {
    return this._abilities.map((ability) => ({
      name: ability.name,
      state: cloneDeep(ability.state),
    }));
  }

  use(name: AbilityName | null, move: Move): Effect[] {
    return this.get(name).fn(move);
  }
}
