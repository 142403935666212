import { Board } from '../Board';
import { Point } from '../Point';
import { getPointsFromMoves } from '../utils/movement';
import { Piece, PieceEntity } from './Piece';

export class UndirectionedPiece extends Piece {
  moves!: Point[];

  constructor(data: Partial<PieceEntity>, board: Board) {
    super(data, board);
  }

  override canAttackPoint(point: Point): boolean {
    return this.moves.some((move) => {
      const isPossible = this.point.add(move).equals(point);
      const piece = this.board.getPieceAtPoint(point);
      return (
        isPossible &&
        (!piece || (piece.player !== this.player && piece.canBeCaptured))
      );
    });
  }

  override getMovementPoints(): Point[] {
    return getPointsFromMoves(this.point, this.moves, this.board);
  }
}
