import { ClientEvent } from 'pixie-dust';
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { InviteLinkInput } from '../../components/InviteLinkInput';
import { useStore } from '../../store';
import { getInviteLink } from '../../utils/invite';

function AcceptInvite() {
  const { id: inviteId } = useParams();
  const location = useLocation();
  const emit = useStore((state) => state.emit);
  const isConnected = useStore((state) => state.isConnected);
  const userId = useStore((state) => state.userId);

  useEffect(() => {
    if (!inviteId || !isConnected) {
      return;
    }
    emit(ClientEvent.AcceptInvite, inviteId, userId, location.state?.listen);
  }, [emit, userId, inviteId, isConnected, location.state?.listen]);

  return (
    <div className="flex w-full items-center justify-center">
      <InviteLinkInput waiting inviteLink={getInviteLink(inviteId ?? '')} />
    </div>
  );
}

export default AcceptInvite;
