import { Player } from 'pixie-dust';
import { useEffect, useRef, useState } from 'react';
import { BOARD_SIZE, Color } from '../../utils/constants';

interface CoordinateLayerProps {
  player: Player | null;
}

export function CoordinateLayer({ player }: CoordinateLayerProps) {
  const [svgWidth, setSvgWidth] = useState(250);
  const svgRef = useRef<SVGSVGElement>(null);

  // Resize text
  useEffect(() => {
    const handleResize = () => {
      if (svgRef.current) {
        setSvgWidth(svgRef.current.clientWidth);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <svg
      ref={svgRef}
      className="absolute size-full"
      xmlns="http://www.w3.org/2000/svg"
      pointerEvents="none"
    >
      {Array.from({ length: BOARD_SIZE }).map((_, i) => {
        return (
          <text
            key={i}
            className="select-none font-inter"
            x={`${(i + 1) * (100 / BOARD_SIZE) - 1.5}%`}
            y="99%"
            textAnchor="middle"
            style={{
              fill: i % 2 === 1 ? Color.TileDark : Color.TileLight,
              fontSize: `${(svgWidth / BOARD_SIZE) * 0.02}rem`,
            }}
          >
            {player === Player.White
              ? String.fromCharCode(97 + i)
              : String.fromCharCode(97 + BOARD_SIZE - i - 1)}
          </text>
        );
      })}
      {Array.from({ length: BOARD_SIZE }).map((_, i) => {
        return (
          <text
            key={i}
            className="select-none font-inter"
            x="1.75%"
            y={`${(i + 1) * (100 / BOARD_SIZE) - 10}%`}
            textAnchor="middle"
            style={{
              fill: i % 2 === 0 ? Color.TileDark : Color.TileLight,
              fontSize: `${(svgWidth / BOARD_SIZE) * 0.02}rem`,
            }}
          >
            {player === Player.White ? BOARD_SIZE - i : i + 1}
          </text>
        );
      })}
    </svg>
  );
}
