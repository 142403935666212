import { FallbackProps } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';
import { Button } from '../components/Button';

export const ErrorFallback = ({ resetErrorBoundary }: FallbackProps) => {
  const navigate = useNavigate();

  return (
    <div className="flex w-full flex-col items-center justify-center">
      <p className="mb-5 w-1/2 min-w-[350px] text-center text-3xl text-pixie-gray-2">
        Something went wrong. We'll fix this as fast as possible! If you'd like,
        submit any relevant information via the Feedback button in the bottom
        right. Try refreshing the page or starting a new game if the error
        persists.
      </p>
      <Button
        onClick={() => {
          navigate('/invite');
          resetErrorBoundary();
        }}
      >
        New Game
      </Button>
    </div>
  );
};
